try { 
this["vv"]["timer"] = // timer 
{
		
		active : false,
		counter : 0,
		running : false,
		loadingImage : false,
		
		init : function (time) {
			vv.timer.active = true;
			setInterval( vv.timer.tick, time);
		},
		
		start : function () {
			vv.timer.counter = 0;
			vv.timer.active = true;
		},
		
		restart : function () {
			vv.timer.reset();
			vv.timer.active = true;
		},
		
		hardStop : function () {
			vv.timer.running = false;
			vv.timer.active = false;
			vv.timer.counter = 0;
			vv.props.engine.stopRenderLoop();			
		},

		tick : function () {
			if (vv.timer.active) {
				vv.timer.counter ++;
//				console.log("TILES LOADED : " + vv.mngr.tiles.tilesLoaded());
//				if (vv.timer.counter >= 4 ) {
				if (! vv.timer.loadingImage && ( (vv.timer.counter >= 2500 && vv.mngr.tasks.isDone()) || vv.timer.counter >= 2500 )) {
					vv.timer.hardStop();
				}
			}
		},
				
		reset : function () {
			if (!vv.timer.running) {
       		 	vv.timer.running = true;
				vv.engine.run();			
			}
//			vv.timer.active = false;
			vv.timer.counter = 0;			
		},
				
};
 }
 catch (e) { 
 // pass 
 }