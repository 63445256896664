try { 
this["vv"]["mngr"]["entities"] = // entities
{
    create : function (entity) {
        
        var newLevel = vv.utils.getCurrentLevel();
          
        if (vv.utils.getCurrentLevel() >= entity.MAX_LEVEL || vv.utils.getCurrentLevel() < entity.MIN_LEVEL) {
            entity.currentLevel = -1;
            vv.mngr.entities.removeMeshes(entity.id, entity.scene);
            return;
        }
        
        if (entity.currentLevel == newLevel) return;
        
        if (entity.currentLevel != -1) {
        	vv.utils.removeMeshById(entity.id);
        }
             
        entity.currentLevel = newLevel;
        entity.update(entity.scene);
    },
    
};
 }
 catch (e) { 
 // pass 
 }