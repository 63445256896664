try { 
this["vv"]["obj"] = this["vv"]["obj"] || {};
this["vv"]["obj"]["AnimationTask"] = // AnimationTask
function (id, taskId, s) {
	this.surface = s;
	this.taskId = taskId;
	this.id = id;
	this.running = false;
};
 }
 catch (e) { 
 // pass 
 }