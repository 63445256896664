try { 
this["vv"]["mngr"]["surfaces"] = // surfaces
{
	max_tiles : 7,
    ALL_TILES : 15,
    
    createTiles : function (s, taskType, xdif, ydif) {
    	console.log('displayType', s.displayType, s.level );
        
        var level = s.level;    // needed ?
        if (level < 0) { level = 0;}
        var t = vv.obj.Tile('void', 0, 0, level);	// based on 256x256 tiles
        if (! t ) return;

        var center_x = 0;
        var center_y = 0;
        var counter = 0;

        if (level <= 1) {
            for (var y=0; y < t._my; y++) {
                for (var x = 0; x < t._mx; x++) {
                	counter += vv.mngr.surfaces.prepareTile(s, x, y);
                }
            }   
            s.setSurface(center_x, center_y);
        } else {     
            var x,y;
            var arr = vv.mngr.tiles.getTileFromAB(level, vv.mngr.camera.activeCamera.alpha, vv.mngr.camera.activeCamera.beta);
            [x,y] = vv.mngr.tiles.correctTile(arr[0] + xdif, t._mx, arr[1] + ydif, t._my);
            if (xdif == 0 && ydif == 0) {
            	s.setSurface(x, y);
            }
            center_x = x;
            center_y = y;
            var all_tiles = (s.displayType == 'main') ? 7 : vv.mngr.surfaces.ALL_TILES;
            var diff = (vv.mngr.surfaces.max_tiles - 1 ) / 2;
            var _out = (all_tiles - vv.mngr.surfaces.max_tiles ) / 2;
            for (var i=0; i < all_tiles; i++) {
                for (var j=0; j<all_tiles; j++) {
                    var outside = false;
                    if (i < _out || i >= (all_tiles - _out)) { outside = true;} 
                    if (j < _out || j >= (all_tiles - _out)) { outside = true;} 
                    if (x-diff + i - _out < 0 || x-diff + i - _out >= t._mx ||
                    		y-diff + j - _out < 0 || y-diff + j - _out >= t._my) {
                    	continue;
                    }
                    var coord = vv.mngr.tiles.correctTile(x-diff + i - _out, t._mx, y-diff + j - _out, t._my);
                    if (s.displayType == 'main') {
                        if (!outside) {
                        	counter += vv.mngr.surfaces.prepareTile(s, coord[0], coord[1]);
                        }                    	
                    } else {
                    	counter += vv.mngr.surfaces.prepareTempTile(s, coord[0], coord[1]);
                    }
                }
            }
            if (level > 1 && level <= vv.mngr.tiles._tileRefreshLevel && s.displayType == 'temp') {
            	for (j=0; j < t._my; j++) {
                    for (var i = 0; i < t._mx; i++) {
                    	counter += vv.mngr.surfaces.prepareTempTile(s, i, j);
                    }
                }   
            }
            console.log('total tiles preped = ' + counter + ' for level ' + s.level + ', ' + s.displayType);
        }
        if (s.displayType == 'main') {
        	vv.mngr.surfaces.prepareSphereTile(s);
        } else {
        	vv.mngr.surfaces.prepareTempSphereTile(s);
        }
//        s.setSurface(center_x, center_y);
        if (counter > 0 && s.displayType == 'main') {
            vv.mngr.tasks.makeTiles(s, taskType, center_x, center_y);        	
        }
    },
    
    prepareTile : function (s, x, y) {
//    	console.log(x , y);
    	var tile = s.getTileById(vv.mngr.tiles.getTileId(s.getBaseId(), x, y));
    	if (! tile) {
    		tile = new vv.obj.Tile(s.getBaseId(), x, y, s.level);
    		s.tiles.push(tile);
    	} 
//    	if (! tile.loaded) {
    	if (! tile.ready()) {
        	if (! vv.props.isGeo && (tile.y <= s.parent[s.level]["conversion"].minTile || tile.y >= s.parent[s.level]["conversion"].maxTile)) {
//        		console.log(y, tile.y);
        		vv.mngr.tiles.updateTempTile(s, tile, s.parent, true);
        		return 0;
        	}
            return 1;
    	}
    	if (! vv.props.isGeo && tile.partial) {
    		return 1;
    	}
		vv.mngr.tiles.updateTile(s, tile);
		return 0;
    },
    
    prepareTempTile : function (s, x, y) {
    	var tile = s.getTileById(vv.mngr.tiles.getTileId(s.id, x, y));	// main tile
		var tempTile = s.getTileById(vv.mngr.tiles.getTileId(s.getBaseId(), x, y));	// temp tile
    	// actual "main" tile exists so no need for a temp
    	if (tile && tile.ready()) {
    		if (tempTile) {
    			s.removeTileById(tempTile.id);
    		}
    		return 0;
    	}
    	if (! tempTile) { 
    		tempTile = new vv.obj.Tile(s.getBaseId(), x, y, s.level);
    		s.tiles.push(tempTile);
    	}
		vv.mngr.tiles.updateTempTile(s, tempTile, s.parent, false);
        return 1;
    },
    
    prepareSphereTile : function (s) {
		var level = vv.utils.getCurrentLevel();
    	if (level > vv.mngr.tiles._tileRefreshLevel) {
    		return;
    	}
    	var sid = s.getMainSphereId();
    	var id = vv.mngr.tiles.getSphereTileId(sid);
    	var tile = s.getTileById(id);
    	console.log(tile);
    	if (tile) {
        	if (tile.loaded) {
        		if (s.level == 1 && s.level != level) {
        			if (s.parent[level] && s.parent[level]["main"]) {
        				var s2 = s.parent[level]["main"];
        				var sid2 = s2.getMainSphereId();
        				var tile2 = s2.getTileById(vv.mngr.tiles.getTileId(sid2, 0, 0));
        				if (tile2 && !tile2.partial) {
        					return;
        				}
        			}
        		}
        		vv.mngr.tiles.setSphereTexture(s);
        		//vv.mngr.tiles.updateTile(s, tile);
        	} 
    	} else {
    		tile = new vv.obj.Tile(sid, -1, -1, s.level);
    		tile.partial = true;
    		tile.composite = true;
    		s.tiles.push(tile);
    		vv.mngr.tiles.setSphereTexture(s);
    		if (! tile.partial) {
    			tile.alpha = 1;
    			tile.material.alpha = tile.alpha; 
    		}
    	}
/*    	var mat =  new BABYLON.StandardMaterial(this.id + "moon", this.scene);
        mat.diffuseTexture = new BABYLON.Texture("/images/BlackMarble_2016_2x1.jpg", this.scene);
        mat.backFaceCulling = false;
        mat.alpha = 1.0;
    var sphereM = BABYLON.MeshBuilder.CreateSphere(this.id, { diameter: vv.props.r*2 }, this.scene);
    sphereM.material = mat;
    sphereM.position.z = 0.005;*/
    
    },
    
    prepareTempSphereTile : function (s) {
    	if (s.level > vv.mngr.tiles._tileRefreshLevel) {
    		return;
    	}
    },
    
    clearTempSurfaceTiles : function (s) {
    	var level = vv.utils.getCurrentLevel();
		if (s.parent[level] && s.parent[level]["temp"]) {
			s.parent[level]["temp"].clear();
		}
    },
    
    clearTempTiles : function (s) {
    	if (! s.parent || ! s.parent[s.level] || ! s.parent[s.level]["temp"]) {
    		return;    		
    	}
    	var temp = s.parent[s.level]["temp"];
    	for (var i=0; i<s.tiles.length; i++) {
    		if (s.tiles[i].loaded) {
    			temp.removeTileById(vv.mngr.tiles.getTileId(temp.getBaseId(), s.tiles[i].x, s.tiles[i].y));
    		}
    	}
    },
    
    updateTempTiles : function (s) {
    	var level = vv.utils.getCurrentLevel();
    	if (s.parent[level] && s.parent[level]["temp"]) {
    		// needs tween fixing as it starts fully loaded (alpha = 1.0)
    		s.parent[level]["temp"].visible = false;
    		s.parent[level]["temp"].show(vv.mngr.tasks.TASK_LEVEL);
    	}
    },
    
    
    getSurfaceId : function (mapId, level) {
    	return mapId + '-' + level;
    },
    
    clean : function (planet) {
    	var id = planet.getMVId();
    	var removal = [];
    	
    	for (var univ in planet.multiverse) {
    		if (!(univ == id)) {
    			var active = false;
    			for (var i=0; i<planet.multiverse[univ].length; i++) {
    				var surface = planet.multiverse[univ][i];
    				if (surface) {
    					if (surface["main"].animated) {
    						active = true;
    					} else {
    						surface["main"].clear();
    						if (surface["temp"]) {
    							surface["temp"].clear();
    						}
        					planet.multiverse[univ][i] = null;
    					}
    				}    					
    			}
    			if (!active) {
    				removal.push(univ);
    			}
    		}
    	}
    	for (var j=0; j<removal.length; j++) {
    		delete planet.multiverse[removal[j]];
    	}
    },
    
    clearLevelTiles : function (s) {
        var lambda = 9;
        for (var i=s.tiles.length-1; i>=0; i--) {
        	var tile = s.tiles[i];
            if (!((Math.abs(tile.x - s.centerX) <= lambda || (tile.x + tile._mx - s.centerX <= lambda)) &&
            		(Math.abs(tile.y - s.centerY) <= lambda))) {
            	tile.clearAll();
                s.tiles.splice(i, 1);
            }    
        }
    },
    
    hideLevel1 : function (planet) {
		for (var id in planet.multiverse) {
			if (planet.multiverse[id][0]["main"] && planet.multiverse[id][0]["main"].sphereTextureLoaded && planet.multiverse[id][1]["main"]) {
				planet.multiverse[id][1]["main"].hide();
			}
		}
    }

};
 }
 catch (e) { 
 // pass 
 }