try { 
this["vv"]["events"] = // events
{
	VV_ANIMATION_END	: 'vv_animation_end',
	VV_IMAGE_TWEEN 		: "vv_tween",
	VV_LAYER_CHANGE 	: 'vv_layer_change',
	VV_READY			: "vv_ready",
	VV_TILE_TASK_END	: 'vv_tile_task_end',
	VV_TILE_TASK_START 	: 'vv_tile_task_start',
	
	_set : false,
	
	setListeners : function () {
		if ( vv.events._set ) { return; }
        vv.events._set = true;
        window.addEventListener("resize", vv.events.resize);            
        window.addEventListener("mousedown", vv.events.mouseDown);
        window.addEventListener("keydown", vv.events.keyDown);
        window.addEventListener("touchstart", vv.events.touchStart);            
        window.addEventListener("wheel", vv.events.mouseWheel); 
 //           window.addEventListener("DOMMouseScroll", vv.events.mouseWheel); 
        window.addEventListener("mouseup", vv.events.mouseUp);         
        window.addEventListener("touchend", vv.events.touchEnd);           

	},
	
	removeListeners : function () {
		if ( ! vv.events._set ) { return; }
		vv.events._set = false;
        window.removeEventListener("resize", vv.events.resize);            
        window.removeEventListener("mousedown", vv.events.mouseDown);
        window.removeEventListener("keydown", vv.events.keyDown);
        window.removeEventListener("touchstart", vv.events.touchStart);            
        window.removeEventListener("wheel", vv.events.mouseWheel); 
//            window.removeEventListener("DOMMouseScroll", vv.events.mouseWheel); 
        window.removeEventListener("mouseup", vv.events.mouseUp);         
        window.removeEventListener("touchend", vv.events.touchEnd);            
		
	},
	
	keyDown : function (e) { vv.timer.reset(); },
	mouseDown : function (e) { vv.timer.reset(); },
	touchStart : function (e) { vv.timer.reset(); },
	
	resize : function (e) {
		vv.timer.reset();
    	vv.props.engine.resize();
	},
	
	
	mouseWheel : function (e) {
        vv.mngr.camera.updateRadius(e);
		vv.timer.restart();
		//vv.utils.setOLMapView();
//			vv.engine.setScene();
	},
	
	mouseUp : function (e) { vv.timer.start(); vv.utils.setOLMapView(); vv.mngr.camera.updatePosition(null);},
	touchEnd : function (e) { vv.timer.start(); vv.utils.setOLMapView(); vv.mngr.camera.updatePosition(null);},
	
    vvReady: function() {
    	vv.events.dispatchEvent(vv.events.VV_READY, null);	    	
    },
    
    vvImageTween : function () {
    	vv.events.dispatchEvent(vv.events.VV_IMAGE_TWEEN, null);
    },
    
    dispatchEvent : function (event, detail) {
        if (vv.props.isIE) {
            var evt = document.createEvent("Event");
            evt.initEvent(event, true, false);
            if (detail) {
            	evt.detail = detail;
            }
            document.dispatchEvent(evt);
        } else {
	        document.dispatchEvent(new CustomEvent(event, {'detail': detail } ) );	        		
        }	    	
    }
};
 }
 catch (e) { 
 // pass 
 }