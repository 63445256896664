try { 
this["vv"]["mngr"]["rotation"] = {
	speed : 100,
	timer : null,
	earthRotation : (Math.PI / 180.0) * 5.0,
	earthDay : 330,
	earthYear : 2000,
	currentRotation : 0.0,
	centerPosition : null,
	enabled : true,
	
	init : function () {
		vv.mngr.rotation.centerPosition = new BABYLON.Vector3(0, 0, 100); 
	},
	
	rotate : function () {
		if (!vv.props.realism) {
			return;
		}
		vv.mngr.rotation.currentRotation += vv.mngr.rotation.earthRotation;
		if (Math.abs(vv.mngr.rotation.currentRotation) >= 2 * Math.PI) { 
			vv.mngr.rotation.currentRotation = 0.0;
			vv.mngr.rotation.earthDay ++;
//			console.log(vv.mngr.rotation.earthDay);
		}
		if ((vv.mngr.rotation.earthDay == 365 && vv.mngr.rotation.earthYear % 4 != 0) || (vv.mngr.rotation.earthDay == 366 && vv.mngr.rotation.earthYear % 4 == 0)) {
			vv.mngr.rotation.earthYear++;
			vv.mngr.rotation.earthDay = 0;
		}
		vv.mngr.rotation.computeRotation();
		for (var i=0; i<vv.props.components.length; i++) {
			vv.props.components[i].instance.rotate();
		}
	},
	
	computeRotation : function () {
		var step = vv.mngr.rotation.earthDay + vv.mngr.rotation.currentRotation / (2 * Math.PI); 
		var origin = BABYLON.Vector3.Zero();
		
//    	var radius = 100*(1-0.017*0.017)/(1+0.017* Math.cos( step / 365.25*360));
    	var radius = vv.props.r * 100 ;
    	var angle = -(step / 365.25) * 2 * Math.PI;
		var v = new BABYLON.Vector3(radius, angle, 0);
		vv.mngr.rotation.centerPosition = vv.utils.spher2Cart(v).add( origin );
	
	},
	
	pause : function () {
		if (vv.mngr.rotation.timer) {
			clearInterval(vv.mngr.rotation.timer);
			vv.mngr.camera.activeCamera.useAutoRotationBehavior = false;
			vv.mngr.rotation.timer = null;
		}
	},
	
	start : function () {
		if (vv.mngr.rotation.enabled && ! vv.mngr.rotation.timer) {
			vv.mngr.rotation.timer = setInterval( vv.mngr.rotation.rotate, 10);
			vv.mngr.camera.activeCamera.useAutoRotationBehavior = true;			
		}
	}
};
 }
 catch (e) { 
 // pass 
 }