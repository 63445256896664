try { 
this["vv"]["plugins"] = this["vv"]["plugins"] || {};
this["vv"]["plugins"]["ol"] = this["vv"]["plugins"]["ol"] || {};
this["vv"]["plugins"]["ol"]["main"] = // open layers support
{
    maps : [],
    _div : null,
    activeMap : -1,
    activeMaps : [],
    task : null,
    blocker : -1,
    currentTask : null,
    
    init : function(div) {
        vv.plugins.ol.main._div = document.getElementById(div);
        vv.plugins.ol.utils.createSupportMaps(vv.plugins.ol.main._div); // may need to tie this with a layer
        document.addEventListener(vv.events.VV_TILE_TASK_START, vv.plugins.ol.main.newTileTask);     
        //vv.plugins.ol.main.updateLayers();
    },
    
    newTileTask : function (evt) {
    	var task = evt.detail; 
    	var s = task.surface;
    	
    	console.log("Textures: " + s.level + ", " + task.centerX + ", " + task.centerY, task.taskType);
        
        if (s.level > vv.props.MAX_LEVEL || s.level < vv.props.MIN_LEVEL ) { return; }
        
        var mo = vv.plugins.ol.utils.setActiveMap(s.level);  
        console.log(mo);
        
//        var mo = vv.plugins.ol.main.maps[vv.plugins.ol.main.activeMap];        
        mo.task = task;
        mo.setTileInfo(s.level, task.centerX, task.centerY);
        vv.plugins.ol.main.proceedLoadingTexture(mo);
    },
    
    proceedLoadingTexture : function (mo) {
        if (! mo.ready) {           // check if map has loaded layers
            vv.plugins.ol.main.updateLayers(mo);
            if (!mo._resized) {
                window.dispatchEvent(new Event('resize'));
            }
            return;
        }
        if (mo.active) {          // if layers are loaded, load texture
            vv.plugins.ol.utils.loadTexture(mo);  
        }
    },
    
    checkStatus : function () {
//        var mo = vv.plugins.ol.main.maps[vv.plugins.ol.main.activeMap];
        var mo = vv.plugins.ol.main.getMapByIndex(vv.plugins.ol.main.activeMap);
        mo.checkStatus();
        if (vv.plugins.ol.main.blocker >=0) {
        	vv.plugins.ol.main.blocker ++;
        }
        if (vv.plugins.ol.main.blocker > 3) {        	
//        	vv.plugins.ol.main.setMapCounter();
        	vv.mngr.tasks.tilesLoaded(vv.plugins.ol.main.currentTask);
        	vv.plugins.ol.main.blocker = -1;
        }
    },
    
    getMapById : function (id) {
        for (var i=0; i<vv.plugins.ol.main.maps.length; i++) {
            var mo = vv.plugins.ol.main.maps[i];
            if (mo.id == id) { return mo;}
        }
        return null;
    },
    
    getMapByIndex : function (index) {
        for (var i=0; i<vv.plugins.ol.main.maps.length; i++) {
            var mo = vv.plugins.ol.main.maps[i];
            if (mo.index == index) { return mo;}
        }
        return null;
    },
    
    updateLayers : function (mo) {
        var index = 0;
        mo.startLoading();
        mo.ready = true;
        // needs remove layers
        vv.props.map.getLayers().forEach(function(layer) {
            mo.map.getLayers().insertAt(index, layer);                
            index++;
        });             
    },
        
    postComposeHandler : function (evt) {
        var id = evt.target.getProperties().target;
        for (var i=0; i<vv.plugins.ol.main.maps.length; i++) {
            var mo = vv.plugins.ol.main.maps[i];
            if (mo.id == id) { 
//            	console.log('postcompose');
                mo.postCompose();
                return;
            }            
        }
    },
  
};
 }
 catch (e) { 
 // pass 
 }