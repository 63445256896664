try { 
this["vv"]["entity"] = this["vv"]["entity"] || {};
this["vv"]["entity"]["Atmosphere"] = // Atmosphere 
function (id) {
	this.currentLevel = -1;
	this.id = id;
	this.MIN_LEVEL = 0;
    this.MAX_LEVEL = 4;
    this.sphere = null;
	
	vv.components.add(new vv.obj.Component({
		id: this.id,
		type: 'atmosphere',
		instance: this
	}) );
    
	this.create = function (scene) {
		this.scene = scene;
        this.update();
    }
	
	this.update = function (type) {
		if (this.currentLevel == vv.utils.getCurrentLevel()) {
			return;
		}
//		console.log('updating milky way to ' + this.currentLevel + ' r:' + (vv.props.r*30));
        this.currentLevel = vv.utils.getCurrentLevel();
		vv.utils.removeMeshById(this.id);
		if (this.currentLevel >= this.MIN_LEVEL && this.currentLevel <= this.MAX_LEVEL) {
			this.createAtmosphere();			
		}
	}
	
//	var generator = new BABYLON.ShadowGenerator(512, light);
//    generator.addShadowCaster(sphere);
	this.createAtmosphere = function () {
		var coef = (this.currentLevel <= 1) ? 2.0225 : 2.0125;
	    var sphere = BABYLON.MeshBuilder.CreateSphere(this.id, {diameter: (vv.props.r*coef), segments:64}, this.scene);
	    var material = this.scene.getMaterialByID(this.id);
        if (! material) {
        	material = new BABYLON.StandardMaterial(this.id, this.scene);
//            material.reflectionTexture = new BABYLON.CubeTexture("/sky3/TropicalSunnyDay", this.scene);
//            material.reflectionTexture.level = 0.8
//        	material.reflectionTexture = new BABYLON.CubeTexture("https://www.babylonjs-playground.com/textures/skybox", this.scene);
//            material.diffuseColor = new BABYLON.Color3(0, 0, 0);
            material.diffuseColor = new BABYLON.Color3(0, 0.1, 0.5);
//            material.emissiveColor = new BABYLON.Color3(0.5, 0.5, 0.5);
            material.emissiveColor = new BABYLON.Color3(1, 1, 1);
            material.alpha = 0.01;
            material.specularPower = 16;

        	material.reflectionFresnelParameters = new BABYLON.FresnelParameters();
            material.reflectionFresnelParameters.bias = 0.1;

            material.emissiveFresnelParameters = new BABYLON.FresnelParameters();
            material.emissiveFresnelParameters.bias = 0;
            material.emissiveFresnelParameters.power = 0.1;
            material.emissiveFresnelParameters.leftColor = BABYLON.Color3.White();
            material.emissiveFresnelParameters.rightColor = BABYLON.Color3.Black();

            material.opacityFresnelParameters = new BABYLON.FresnelParameters();
            material.opacityFresnelParameters.power = 0.1;
            material.opacityFresnelParameters.leftColor = BABYLON.Color3.White();
            material.opacityFresnelParameters.rightColor = BABYLON.Color3.Black();
            /*
        	material = new BABYLON.StandardMaterial(this.id, this.scene);
            material.reflectionTexture = new BABYLON.CubeTexture("/sky2/TropicalSunnyDay", this.scene);
            material.diffuseColor = new BABYLON.Color3(0, 0, 0);
            material.emissiveColor = new BABYLON.Color3(0.5, 0.5, 0.5);
            material.alpha = 0.2;
            material.specularPower = 16;

        	material.reflectionFresnelParameters = new BABYLON.FresnelParameters();
            material.reflectionFresnelParameters.bias = 0.1;

            material.emissiveFresnelParameters = new BABYLON.FresnelParameters();
            material.emissiveFresnelParameters.bias = 0.6;
            material.emissiveFresnelParameters.power = 4;
            material.emissiveFresnelParameters.leftColor = BABYLON.Color3.White();
            material.emissiveFresnelParameters.rightColor = BABYLON.Color3.Black();

            material.opacityFresnelParameters = new BABYLON.FresnelParameters();
            material.opacityFresnelParameters.leftColor = BABYLON.Color3.White();
            material.opacityFresnelParameters.rightColor = BABYLON.Color3.Black();             
             */

        }
        sphere.material = material;
	}
	
/*	this.refresh = function () {
//		console.log("REFRRESH");
		vv.utils.removeMeshById(this.id);
		vv.utils.removeMaterialById(this.id);
		this.createAtmosphere();	
	}
	*/
	this.createAtmosphere2 = function () {
//	    var sphere = BABYLON.MeshBuilder.CreateSphere(this.id, {diameter: (vv.props.r*2.00525), segments:64}, scene);
	    var sphere = BABYLON.MeshBuilder.CreateSphere(this.id, {diameter: (vv.props.r*2.01125), segments:64}, this.scene);
	    var material = this.scene.getMaterialByID(this.id);
        if (! material) {
            material = new BABYLON.StandardMaterial(this.id, this.scene);
            material.diffuseColor = new BABYLON.Color3(0, 0.2, 0.6);
//            material.diffuseColor = new BABYLON.Color3(0, 0.2, 0.6);
//            material.diffuseColor = new BABYLON.Color3(0.3, 0.5, 0.9);
            material.emissiveColor = BABYLON.Color3.White();
//            material.emissiveColor = new BABYLON.Color3(1, 1, 0.8);
           material.specularPower = 128;
            material.alpha = 0.05;
            
            material.indexOfRefraction = 0.5;

	           material.reflectionTexture = new BABYLON.CubeTexture("/sky2/TropicalSunnyDay", this.scene);
	           material.reflectionTexture.level = 0.3;
            
            // working - white
           /* material.diffuseColor = new BABYLON.Color3(0, 0, 0);
            material.emissiveColor = BABYLON.Color3.White();
            material.specularPower = 64;
            material.alpha = 0.15;*/
            
            // blue
/*            material.diffuseColor = new BABYLON.Color3(0, 0.2, 0.6);
            material.emissiveColor = BABYLON.Color3.White();
            material.specularPower = 128;
            material.alpha = 0.1;*/
            
//	              material.diffuseColor = new BABYLON.Color3(0, 0.2, 0.6);
//	              material.emissiveColor = new BABYLON.Color3(0, 0.3, 0.6);
//	                material.emissiveColor = new BABYLON.Color3(0.26, 0.64, 0.98);
//	              material.diffuseColor = new BABYLON.Color3(0, 0, 0);
//	          material.emissiveColor = BABYLON.Color3.White();
//	          material.specularPower = 128;
//	          material.alpha = 0.1;

            // Fresnel
            material.emissiveFresnelParameters = new BABYLON.FresnelParameters();
            material.emissiveFresnelParameters.bias = 0.3;
//	          material.emissiveFresnelParameters.leftColor = BABYLON.Color3.White();
//	          material.emissiveFresnelParameters.rightColor = BABYLON.Color3.Black();
            material.emissiveFresnelParameters.leftColor = BABYLON.Color3.White();
            material.emissiveFresnelParameters.rightColor = new BABYLON.Color3(0, 0, 0);

            material.opacityFresnelParameters = new BABYLON.FresnelParameters();
            material.opacityFresnelParameters.power = 0.3;
            material.opacityFresnelParameters.leftColor = BABYLON.Color3.White();
//	          material.opacityFresnelParameters.leftColor = new BABYLON.Color3(0, 0, 1);
//	          material.opacityFresnelParameters.rightColor = BABYLON.Color3.Black();
            material.opacityFresnelParameters.rightColor = new BABYLON.Color3(0, 0, 0);
        }

        sphere.material = material;
        sphere.isBlocker = true; // For intercepting lens flare
//        this.sphere = sphere;
	}
	
	this.rotate = function () {
	}
};
 }
 catch (e) { 
 // pass 
 }