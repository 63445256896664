try { 
this["vv"]["engine"] = // engine
{
	layer : null,
	atm : null,
	init : function (vvMap, map) {

		vv.props.wrapper = document.getElementById(vvMap);
		if (! vv.props.wrapper) { console.log("Can't initiate on element " + wrapper); return;}
        
        if (! vv.props.setMap(map) ) {
        	console.log ('Unsupported projection');
        	return;
        }        
        
        vv.mngr.loader.start();
        vv.mngr.rotation.init();
        
		vv.props.engine = new BABYLON.Engine(vv.props.canvas, true);
		vv.props.scene = new BABYLON.Scene(vv.props.engine);
		vv.props.scene.preventDefaultOnPointerDown = false;
		vv.props.scene.registerBeforeRender(function () {
		});
		vv.props.scene.clearColor = new BABYLON.Color3(0, 0, 0);


		vv.props.position = new vv.obj.Position();
		vv.props.position.setLevel(1);
//		vv.props.maxRadius = vv.props.r * 10;

        vv.timer.init(vv.props.timeout);
		vv.mngr.camera.setActiveCamera(vv.props.scene);

        var stars =  new vv.entity.Stars('MilkyWay');
        var sun = new vv.entity.Sun('Sun');
        var logo = new vv.entity.Logo('Logo');
          
        var planet = new vv.entity.Planet('Earth');
//        var atm = new vv.entity.Atmosphere('Atmosphere');
        var moon = new vv.entity.Moon('Moon');
        
/*        vv.props.scene.onPointerUp = function (evt, pickResult) {
        	console.log(evt, pickResult);
        }*/

        //vv.timer.start();
	},
		
	start : function () {
        vv.events.setListeners();
		vv.timer.restart();
		vv.engine.createScene();
		//vv.mngr.rotation.start();
	},
	
	stop : function () {
//			console.log('stop');
        vv.events.removeListeners();
		vv.timer.hardStop();
		vv.mngr.rotation.pause();
		//vv.entity.Earth.scene = null;
	},
	
	run : function () {
		vv.props.engine.runRenderLoop(function () {
			vv.props.scene.render();
   	 	});	
	},
		
	createScene : function () {	
		vv.mngr.camera.update();
		for (var i=0; i<vv.props.components.length; i++) {
			vv.props.components[i].instance.create(vv.props.scene);
		}
	},

	update : function (type) {
		vv.mngr.camera.update();
		for (var i=0; i<vv.props.components.length; i++) {
			var comp = vv.props.components[i];
			comp.instance.update(type);
		}
		//vv.mngr.camera.update();
	},
	
	layerChange : function () {
		vv.events.dispatchEvent(vv.events.VV_LAYER_CHANGE, null);		
	},
	pause : function () {
		vv.mngr.rotation.enabled = false;
		vv.mngr.rotation.pause();
	},
	play : function () {
		vv.mngr.rotation.enabled = true;
		vv.mngr.rotation.start();
	}
	
	
};
 }
 catch (e) { 
 // pass 
 }