try { 
this["vv"]["obj"]["Position"] = function Position() {
	this.level = 0;
//	this.alpha = -Math.PI;
	this.alpha = Math.PI/2;
//	this.alpha = Math.PI/2;
	this.beta = Math.PI/2;
//	this.alpha = Math.PI/2;
//	this.beta = Math.PI/2;
	this.radius = 1000;
	
	this.setLevel  = function (level) {
		this.level = level;
		vv.mngr.camera.setRadiusProps(this.level);
		this.radius = vv.utils.getRadiusFromLevel(this.level + 1);
	}
};
 }
 catch (e) { 
 // pass 
 }