try { 
this["vv"]["plugins"]["ol"]["utils"] = // open layers support
{
	tileSize : 256,
	
	createSupportMaps : function (parentDiv) {
//        for (var i = vv.props.MIN_LEVEL; i <= vv.props.MAX_LEVEL; i++) {
    	var pf = (vv.props.isGeo) ? '_g' : '_m'; 
    	for (var i = 0; i <= 2; i++) {
        	var obj = {};
        	obj.max = (i<2) ? 1 : 3;
        	obj.current = 0;
        	vv.plugins.ol.main.activeMaps.push(obj);	

    		for (var j = 0; j <= 2; j++) {
    			if (i < 2 && j > 0 ) { continue; }
	            var mapDiv = document.createElement("div");
	            var ind = i*10 + j;
	            var id = "vv-support-map" + (ind) + pf;
	            mapDiv.setAttribute("id", id);
	            mapDiv.setAttribute("class", "vv-support-map vv-support-map"+i+pf); 
	            mapDiv.setAttribute("crossorigin", "anonymous");
	            parentDiv.appendChild(mapDiv);
	            var map = new vv.plugins.ol.obj.Map(id, i, ind);
	            vv.plugins.ol.main.maps.push(map);	            
    		}
        }
    },
    
    setActiveMap : function (level) {
//    	var index = (level >= 2) ? 2 : level;
    	var index = (level >= 2) ? 2 : level;
    	if (index == 2) {
    		var obj = vv.plugins.ol.main.activeMaps[2];
    		obj.current ++;
    		if (obj.current > 2) { obj.current = 0;}
    		index = index * 10 + obj.current;
    	} else {    		
        	index = index * 10;
    	}
    	var map = vv.plugins.ol.main.getMapByIndex(index);
    	
//    	vv.plugins.ol.main.maps[index].setLevel(level);
    	map.setLevel(level);

    	if (vv.plugins.ol.main.activeMap == -1) {
    		setInterval( vv.plugins.ol.main.checkStatus, 200);     
    	}
        if (vv.plugins.ol.main.activeMap != index) {
            vv.plugins.ol.main.activeMap = index;
            document.getElementById(map.id).style.display = "block";
            document.getElementById(map.id).style.visibility = "visible";
            vv.plugins.ol.utils.clearActiveMaps();
            map.setPostComposeHandler();        	
        }
        return map;
    },
    
    clearActiveMaps : function () {
        for (var i=0; i<vv.plugins.ol.main.maps.length; i++) {
            var mo = vv.plugins.ol.main.maps[i];
            mo._resized = false;
            if (vv.plugins.ol.main.activeMap != mo.index) {
//                document.getElementById(mo.id).style.display = "none";
//                document.getElementById(mo.id).style.visibility = "hidden";
                //mo.active = false;
                //mo.unsetPostComposeHandler();
            }
        }
    },
    
    loadTexture : function (mo) {
        if (mo.level > vv.props.MAX_LEVEL || mo.level < vv.props.MIN_LEVEL ) { return; }
        
        if (!mo.task) {
//        	console.log("Task no longer running ...");
        	return;
        }
        
        var ctx = mo.getCtx();
        var r = mo.getTileRange();
//        console.log(r);
        mo.scale();
        
        if (!mo._resized) {
        	mo._resized = true;
            window.dispatchEvent(new Event('resize'));
            return;
        }
        
        mo.task.loaded = true;

    	if (vv.props.isGeo) {
    		vv.plugins.ol.utils.loadGeoTexture(mo);
                  
    	} else {
    		vv.plugins.ol.utils.loadMercatorTexture(mo);
    	}
    	
//    	console.log('done loading');
    	vv.plugins.ol.main.blocker = 0;
    	vv.plugins.ol.main.currentTask = mo.task;
    	
    	vv.mngr.tiles.setSphereTexture(vv.plugins.ol.main.currentTask.surface);

		//vv.mngr.tasks.tilesLoaded(mo.task);
    },
    
    loadGeoTexture : function (mo) {
        var ctx = mo.getCtx();
        var r = mo.getTileRange();
        var ts = vv.plugins.ol.utils.tileSize;

    	for (var yy = r.starty; yy < r.maxy; yy++) {
            for (var xx = r.startx; xx < r.maxx; xx++) {
                // translate xx and yy
                var tid = vv.mngr.tiles.getTileId(mo.task.surface.getBaseId(), (r.offsetx + xx), (r.offsety + yy));
                var tile = mo.task.surface.getTileById(tid);
                if (!tile) { continue; }
                tile.partial = false;
                var img = ctx.getImageData(xx*ts, yy*ts, ts, ts);
                tile.rawData = new Uint8Array(ts*ts*4);
                var data = tile.rawData; 
                var checksum = 0;
                
                for (var j=0; j < ts; j++) {
                    for (var i=0; i < ts; i++) {
                        var di = j*ts*4 + i*4;
                        data[di] = img.data[di];
                        data[di+1] = img.data[di+1];
                        data[di+2] = img.data[di+2];
                        data[di+3] = img.data[di+3];

                        checksum += data[di] + data[di+1] + data[di+2]+ data[di+3];
                    }            
                }

                vv.mngr.tiles.setTexture(mo.task, tile, checksum, mo.index);
            }
        }
    },
    
    loadMercatorTexture : function (mo) {
        var ctx = mo.getCtx();
        var r = mo.getTileRange();
        var ts = vv.plugins.ol.utils.tileSize;

        
		var _level = mo.level+2;
		var _max = Math.pow(2, _level);
    	var levelX = vv.conversion.resolution(_level);
    	var dLat = 180.0 / (ts * _max);
    	
    	for (var yy = r.starty; yy < r.maxy; yy++) {
    		var metersLatLon = new Array(ts+1);	
    		var metersTileY = new Array(ts+1);
    		for (var j=0; j< metersLatLon.length; j++) {
    			metersLatLon[j] = vv.conversion.metersYToLat(vv.conversion.pixelsToMetersY((_max-1-(r.offsety + yy)) * ts + ts-j, levelX));
    			metersTileY[j] = Math.floor((90 - metersLatLon[j]) / (dLat * ts));
    		}
            for (var xx = r.startx; xx < r.maxx; xx++) {
                var img = ctx.getImageData(xx*ts, yy*ts, ts, ts);
                var checksum = 0;

                var tile = null;
                var currentTileY = -1;
                var startRow = -1;
                var counter = 0 ;
                var data = null;
                var checksum = 0;
        		var offsetY = 0;
        		var skip = false;

                for (var j=0; j < ts; j++) {
                	if (currentTileY != metersTileY[j]) {
                		currentTileY = metersTileY[j];
    	                var tid = vv.mngr.tiles.getTileId(mo.task.surface.getBaseId(), (r.offsetx + xx), metersTileY[j]);
                		tile = mo.task.surface.getTileById(tid);
                		if (!tile) {
                			vv.mngr.surfaces.prepareTile(mo.task.surface, (r.offsetx + xx), metersTileY[j]);
                			tile = mo.task.surface.getTileById(tid);
                		} 
                		if (! tile.texture) {
                			tile.texture = new vv.obj.Texture();
                		}
                		if (!tile.rawData) {
                            tile.rawData = new Uint8Array(ts*ts*4);
                		} 
            			data = tile.rawData;
            			checksum = tile.checksum;           
                		
                		if (j>0) {
	                		startRow = Math.round((90 - metersTileY[j] * (dLat * ts) - metersLatLon[j-1]) / dLat );
                		} else if (r.offsety + yy > 0){
                			var temp = vv.conversion.metersYToLat(vv.conversion.pixelsToMetersY((_max-1-(r.offsety + yy-1)) * ts + 1, levelX));
                			startRow = Math.round((90 - metersTileY[j] * (dLat * ts) - temp) / dLat );
                		} else {
                			startRow = Math.round((90 - metersTileY[j] * (dLat * ts) - metersLatLon[j]) / dLat );	
                		}
                		if (r.offsety + yy == 0 && j == 0) {
                			startRow = 0;
                		}
                		if (startRow < 0) { startRow = 0;}

                		offsetY = 90.0 - (metersTileY[j] * 180 / _max);
//                		console.log(tile.id, startRow, offsetY, (offsetY - startRow * dLat));
//                		skip = (tile.partial) ? false : true;
//                		console.log('skip ' + tile.id, skip);
                	}
                	if (skip) {
                		continue;
                	}
                	for (var i=0; i < ts; i++) {
                        counter = startRow;  
                        var di = j*ts*4 + i*4;
                        
	                	degLat = offsetY - counter * dLat;  
                        if (metersLatLon[j] <= degLat || (r.offsety + yy == _max-1 && j == ts-1)) {
                        	var running = true;
                        	while (running) {
	                        	var di2 = counter*ts*4 + i*4;
                        		
    	                        data[di2] = img.data[di];
    	                        data[di2+1] = img.data[di+1];
    	                        data[di2+2] = img.data[di+2];
    	                        data[di2+3] = img.data[di+3];	 
    	                        tile.parts[counter] = 1;
    	                        checksum += data[di2] + data[di2+1] + data[di2+2]+ data[di2+3];
	                        	counter ++;
	                        	degLat = offsetY - counter * dLat;
	                        	if (r.offsety + yy == _max-1 && j == ts - 1) {
	                        		running = true;
	                        	} else if (degLat <= metersLatLon[j] && counter < 255) {
	                        		running = false; 
	                        	}
	                        	if (counter > 255) {
	                        		running = false;
	                        	}
                        	}
                        }
                	}

                    startRow = counter;  

                	if (currentTileY != metersTileY[j+1] || j == ts - 1) {
                		vv.mngr.tiles.checkPartial(tile);
                		vv.mngr.tiles.setTexture(mo.task, tile, checksum, mo.index);
                	}
                }

            }
    	}
    }
    
};
 }
 catch (e) { 
 // pass 
 }