try { 
this["vv"]["obj"]["Task"] = // Task
function (id, s, taskType) {
	this.id = id;
	this.taskType = taskType;
	this.loaded = false;
	this.finished = false;
	this.running = false;
	this.surface = s;
	this.centerX = -1;
	this.centerY = -1;
};
 }
 catch (e) { 
 // pass 
 }