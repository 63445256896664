try { 
this["vv"]["entity"]["Logo"] = // Logo
function (id) {
	this.scene = null;
	this.currentLevel = -1;
	this.id = id;
    
	vv.components.add(new vv.obj.Component({
		id: this.id,
		type: 'logo',
		instance: this
	}) );
	
	this.create = function (scene) {
		this.scene = scene;
        this.update();
    }
	
	this.update = function (type) {
		if (this.currentLevel == vv.utils.getCurrentLevel()) {
			return;
		}
//		console.log('updating milky way to ' + this.currentLevel + ' r:' + (vv.props.r*30));
        this.currentLevel = vv.utils.getCurrentLevel();
		this.deleteMesh();
		this.createLogo();			
	}
	
	this.deleteMesh = function () {
		vv.utils.removeMeshById(this.id);
	}
	
	this.destroy = function () {
		this.deleteMesh();
		vv.utils.removeMaterialById(this.id);
		vv.utils.removeTextureById(this.id);
	}
	
	this.createLogo = function () {
        var mat = this.scene.getMaterialByID(this.id);
        if (! mat) {
            mat = new BABYLON.StandardMaterial(this.id, this.scene);
//            mat.diffuseTexture = new BABYLON.Texture("/images/vuravura_texture.jpg", this.scene);
            mat.diffuseColor = new BABYLON.Color3(0.03, 0.1, 0.28);
            mat.backFaceCulling = true;
//            mat.alpha = 0.8;
        }
        var sphereM = BABYLON.MeshBuilder.CreateSphere(this.id, { diameter: vv.props.r*1.99 }, this.scene);
        sphereM.material = mat;
  //      sphereM.rotate(new BABYLON.Vector3(3.0, 0.35, 0.1), 90, BABYLON.Space.WORLD);
	}
	this.rotate = function () {
		
	}
};
 }
 catch (e) { 
 // pass 
 }