try { 
this["vv"]["mngr"] = this["vv"]["mngr"] || {};
this["vv"]["mngr"]["camera"] = // camera
{
	activeCamera : null,
	currentLevel : -1,
	init : true,
	
	setActiveCamera : function (scene) {
        vv.mngr.camera.activeCamera = new BABYLON.ArcRotateCamera("Camera",vv.props.position.alpha, vv.props.position.beta, 
        		vv.props.position.radius, BABYLON.Vector3.Zero(), scene);
        vv.mngr.camera.activeCamera.attachControl(vv.props.canvas, true);
//        vv.mngr.camera.activeCamera.setTarget(new BABYLON.Vector3(0, 0, vv.props.r * 100));
	},
	
	update : function () {
		 if (vv.mngr.camera.currentLevel == vv.utils.getCurrentLevel()) {
	         return;
	     } 
		 
		 var coef = vv.mngr.camera.getCameraRadiusCoef(vv.utils.getCurrentLevel());
	     var currentCoef = vv.mngr.camera.getCameraRadiusCoef(vv.mngr.camera.currentLevel);         
	     
	     vv.mngr.camera.currentLevel =  vv.utils.getCurrentLevel();
	     vv.mngr.camera.setRadiusProps(vv.utils.getCurrentLevel());
	     
	     if (vv.mngr.camera.init) {
	    	 vv.mngr.camera.init = false;
	    	 currentCoef = coef;
	     }
	     if (vv.mngr.camera.currentLevel < 2) {
	    	 vv.mngr.rotation.start();
	     } else {
	    	 vv.mngr.rotation.pause();
	     }
	
//	     console.log('CAMERA : ' + vv.utils.getCurrentLevel());
	     var _min = (coef >= 100) ? 10 : 1;
	     vv.mngr.camera.activeCamera.lowerRadiusLimit = vv.props.r + _min;          
	     var maxLevel = vv.utils.getLevelFromRadius(vv.props.r + _min);
	     if (maxLevel > vv.props.MAX_LEVEL) {
	    	 vv.mngr.camera.activeCamera.lowerRadiusLimit = vv.utils.getRadiusFromLevel(vv.props.MAX_LEVEL);
	     }
	     vv.mngr.camera.activeCamera.upperRadiusLimit = vv.props.r * 9.35;
	     vv.props.position.radius = vv.props.position.radius * coef / currentCoef;
	     vv.mngr.camera.activeCamera.radius = vv.props.position.radius; 
	     vv.mngr.camera.setUISensitivity();
	},
	
	setUISensitivity : function () {
        var prec = [1000, 1000, 2000, 4000, 8000, 16000, 32000, 64000, 80000, 128000, 150000, 180000, 256000, 370000, 512000];
//		vv.mngr.camera.activeCamera.wheelPrecision = 10 * Math.pow(2, vv.utils.getCurrentLevel());
        var level = vv.utils.getCurrentLevel(); 
		if ( level <= 9 ) {
			vv.mngr.camera.activeCamera.wheelPrecision = 100 - level * 10;	
		} else {
			vv.mngr.camera.activeCamera.wheelPrecision = 10;
		}
		
		var sens = 0;
        if (level > 13) { sens = prec[13]; }
        else if (level < 0) { sens = prec[0]; }
        else { sens =  prec[level]; }
        vv.mngr.camera.activeCamera.angularSensibilityX = sens;
        vv.mngr.camera.activeCamera.angularSensibilityY = sens;
	},
	
	setRadiusProps : function (level) {
    	var coef = vv.mngr.camera.getCameraRadiusCoef(level);
    	//console.log(coef);
        vv.props.r = 6.4 * coef;
        vv.props.maxRadius = vv.props.r * 10;
    },
	
	updateRadius : function (e) {
        vv.mngr.camera.updatePosition(vv.mngr.camera.activeCamera.radius);
    },
    
    updatePosition : function (rad) {
    	var type = (rad) ? 'level' : 'location';
        if (! rad) { rad = vv.mngr.camera.activeCamera.radius;}
//    	console.log(rad);
        var height = vv.props.canvas.height / 2;
        
        var level = vv.utils.getLevelFromRadius(rad);
        vv.props.position.level = level;
        vv.props.position.alpha = vv.mngr.camera.activeCamera.alpha;
        vv.props.position.beta = vv.mngr.camera.activeCamera.beta;
        vv.props.position.radius = vv.mngr.camera.activeCamera.radius;
        
        if (type == 'location' && vv.mngr.camera.currentLevel != vv.utils.getCurrentLevel()) {
        	type = 'level';
        }
        if (type == 'level' && vv.mngr.camera.currentLevel == vv.utils.getCurrentLevel()) {
	         return;
	     } 
        vv.engine.update(type);
        vv.utils.updateDependencies();
    },
    
    getCameraRadiusCoef : function (level) {
//            var rads = [6.4, 6.4, 6.4, 6.4, 6.4, 12.8, 64.0, 64.0, 64.0, 64];
//        var rads = [1, 1, 5, 10, 25, 50, 100, 250, 500, 1000, 2000, 4000, 8000, 16000, 32000, 64000];
//          var rads = [1, 2, 3, 9, 27, 75, 225, 675, 2000, 6000, 18000, 60000, 120000, 360000, 1000000, 3000000, 9000000];
          var rads = [0.1, 0.2, 0.3, 1, 3, 9, 27, 75, 225, 675, 200, 6000, 18000, 60000, 120000, 360000, 100000];
        if (!level || level < 0) return rads[0];
        if (level > 14) return rads[14];
        return rads[level];
        //vv.projection.planet._currentLevel
    },
};
 }
 catch (e) { 
 // pass 
 }