try { 
this["vv"]["mngr"]["tiles"] = // mngr.tiles
// tiles
{     
        _offset : 0,
        _tileRefreshLevel : 1,
        mainTile : null,
        
        createTile : function (tile, s, alpha) {
        	var scene = s.scene;
            var outer = tile.outer;
            tile = vv.mngr.tiles.registerTile(tile);
            tile.outer = outer;
            
            vv.mngr.tiles._offset = vv.mngr.tiles.getTileOffset(vv.utils.getCurrentLevel());
        },
        
        setTexture : function (task, tile, checksum, mapIndex) {
        	if (! tile) { return; }
            //if ((tile.texture && tile.texture.checksum == checksum) || checksum == 0) {
            if (checksum == 0) {
            	console.log('tiles:: texture not applied');
                return; 
            }
/*            if (tile.mapIndex != -1 && tile.mapIndex != mapIndex) {
            	return;
            } else {
            	tile.mapIndex = mapIndex;
            }*/
            tile.loaded = true;
            if (tile.texture) {
            	tile.clearTexture();
            }
            tile.texture = new vv.obj.Texture();
            tile.checksum = checksum;
        	tile.texture.data = new BABYLON.RawTexture.CreateRGBATexture(tile.rawData, 256, 256,task.surface.scene, true, true, BABYLON.Texture.PLANAR_MODE);
        	
        	tile.clearMeshAndMaterial();

        	vv.mngr.tiles.updateTile(task.surface, tile);
        },
        
        setSphereTexture : function (s) {
        	if ( vv.utils.getCurrentLevel() > vv.mngr.tiles._tileRefreshLevel) {
        		return;
        	}
        	
        	var sid = s.getMainSphereId();
        	var stid = vv.mngr.tiles.getSphereTileId(sid);
        	var tile = s.getTileById(stid);
        	if (!tile) { return; }

        	tile.loaded = true;
        	if (tile.texture && tile.partial) {
        		tile.clearTexture();
        	}
    		var factor = Math.pow(2, s.level);
            tile.texture = new vv.obj.Texture();
            var mx = (vv.props.isGeo) ? 1 : 2;
    		tile.rawData = new Uint8Array(1024 * 512 * mx * factor * factor * 4);
    		var ts = 256;
    		var checksum = 0;
    		var partial = false;
    		for (var y = 0; y < factor * 2 * mx; y++) {
    			for (var x = 0; x < factor * 4; x++) {
        			var id = vv.mngr.tiles.getTileId(s.id, (factor * 4 -1) - x, (factor * 2 * mx -1) - y); 
        			var t = s.getTileById(id);
        			if (! t || ! t.loaded) {
        				console.log('loading temp texture');
        				partial = true;
        				var s2 = s.parent[s.level]["temp"];
        				if (!s2) {
//        					console.log("NO DATA");
        					return; 
        				}
        				var id2 = vv.mngr.tiles.getTileId(s2.getBaseId(), (factor * 4 -1) - x, (factor * 2 * mx -1) - y); 
//        				console.log(s2, id2);
        				t = s2.getTileById(id2);
        				if (! t) {
//            				console.log('NO DATA', id2);
            				return;        					
        				}
        			}
//            			console.log(id);
        			var rawData = t.texture.data.getInternalTexture()._bufferView;
        			for (var jj = 0; jj < ts ; jj++) {
        				for (var ii = 0; ii < ts; ii++) {
        					var dP = (ts * ts * 4 -4) - (jj * ts * 4 + ii * 4);
        					var mP = y * 1024 * factor * 4 * ts + jj * 1024 * factor * 4  + x * ts * 4 + ii * 4;
        					tile.rawData[mP] = rawData[dP];
        					tile.rawData[mP+1] = rawData[dP+1];
        					tile.rawData[mP+2] = rawData[dP+2];
        					tile.rawData[mP+3] = rawData[dP+3];
        			    	checksum += tile.rawData[mP] + tile.rawData[mP+1] + tile.rawData[mP+2] + tile.rawData[mP+3];
        				}
        			}
    			}
    		}  
    		tile.partial = partial;
            tile.checksum = checksum;
//            console.log('creating texture', s.level);
        	tile.texture.data = new BABYLON.RawTexture.CreateRGBATexture(tile.rawData, 1024*factor, 512*factor * mx, s.scene, true, true, BABYLON.Texture.PLANAR_MODE);
        	tile.rawData = null;
        	
        	tile.clearMeshAndMaterial();
        	vv.mngr.tiles.updateTile(s, tile);
        },
        
        updateTile : function (surface, tile) {
        	//console.log (tile.id + ', ' + tile.alpha);
        	if (! tile.material) {
                tile.material = new BABYLON.StandardMaterial(tile.id, surface.scene);
                tile.material.diffuseTexture = tile.texture.data;
                tile.material.diffuseTexture.name = tile.id;
                tile.material.diffuseTexture.hasAlpha = tile.partial;
                tile.material.alpha = tile.alpha;        	
//                tile.material.backFaceCulling = true;
        	}
            if (tile.level >= 1) {
//            	console.log("loading tile: " + tile.id);
//                tile.material.diffuseColor = new BABYLON.Color3(((tile.level+1)*(tile.y+tile.x))%4/4, tile.x % 4 / 4, tile.y % 4 / 4 );
            	
            }
            
            if (vv.utils.getCurrentLevel() <= vv.mngr.tiles._tileRefreshLevel) {
            	if (tile.composite) {
//                	console.log("UPDATE TILES", surface.id, tile.id, surface.radius);
            		tile.mesh = BABYLON.MeshBuilder.CreateSphere(tile.id, { diameter: vv.props.r*2 * surface.radius }, surface.scene);
//            		tile.mesh.position = vv.mngr.rotation.earthPosition;
  //          		tile.mesh.rotate(new BABYLON.Vector3(0, 1, 0), -(vv.mngr.rotation.currentRotation), BABYLON.Space.LOCAL);
            		console.log("ROTATE");
//            		tile.mesh.rotate(new BABYLON.Vector3(0, 0, 1),  -45/180 * Math.PI , BABYLON.Space.GLOBAL);
//            		tile.mesh.position.z = vv.props.r * 100;
            		tile.mesh.rotation.x = -23.5 / 180.0 * Math.PI;
//            		tile.mesh.rotation.z = -23.5;
//            		vv.mngr.tiles.mainTile = tile.mesh;
//            		console.log("TEST");
            		
//            		tile.mesh.rotate(new BABYLON.Vector3(1, 0, 0), 0.5, BABYLON.Space.WORLD);        		 
            		tile.mesh.material = tile.material;            		
            	}
            } else {
                if (!tile.mesh) {
                	if (tile.level == vv.utils.getCurrentLevel() || tile.level == 1) {
//                    if (tile.level == vv.utils.getCurrentLevel() ) {
                        vv.mngr.tiles.create(tile, surface.scene, null);            		
                	} 
                } else {
                    tile.mesh.material = tile.material;            
                }        	            	
            }

        },
        
        updateTempTile : function (surface, tile, surfaces, isMain) {
        	var depth = isMain ? 1 : 2;
            for (var i = tile.level; i >= depth; i--) {
                var a = Math.floor(tile.x / Math.pow(2, (tile.level - i)) );
                var b = Math.floor(tile.y / Math.pow(2, (tile.level - i)) );
//                var tId = vv.mngr.tiles.getTileId(tile.layerId, i, a, b, tile.uniqueMapCounter);
//                var pTile = vv.mngr.tiles.getTileById(tId);
                if (! (surfaces[i] && surfaces[i]["main"])) {
                	continue;
                }
                var s = surfaces[i]["main"];  
                var pTile = s.getTileById(vv.mngr.tiles.getTileId(s.getBaseId(), a, b));
                if (pTile && pTile.texture && pTile.loaded) {
                    var directive = vv.mngr.tiles.getTempDirective(tile, pTile, i);
                	if (tile.linkedId && pTile.id == tile.linkedId) {
                		if (!tile.mesh) {
                			vv.mngr.tiles.create (tile, surface.scene, directive);
                		}
                		break;
                	}
                    tile.clearMeshAndMaterial();
                    tile.material = new BABYLON.StandardMaterial(tile.id, surface.scene);
                    tile.texture = pTile.texture;
                    tile.material.diffuseTexture = tile.texture.data;
//                    tile.material.diffuseColor = new BABYLON.Color3(((tile.level+1)*(tile.y+tile.x))%4/4, tile.x % 4 / 4, tile.y % 4 / 4 );
                    tile.material.diffuseTexture.name = tile.id;
                    tile.material.diffuseTexture.hasAlpha = tile.partial;
                    tile.material.alpha = 1.0;
                    tile.linkedId = pTile.id;
                    vv.mngr.tiles.create (tile, surface.scene, directive);
                    break;
                }
            }
        	
        },
        
        getMainDirective : function () {
        	var directive = {};
            directive.factor = 1.0;
            directive.off_x = 0;
            directive.off_y = 0;
            return directive;
        },
        
        getTempDirective : function (tile, pTile, level) {
            var directive = {};
            directive.tile = pTile;
            directive.factor = Math.pow(2, tile.level - level);
            directive.off_x = tile.x - pTile.x * directive.factor;
            directive.off_y = ((pTile.y+1) * directive.factor) - tile.y - 1;
            return directive;
        },
        
        create : function (tile, scene, directive) {
        	if (! directive) {
        		directive = vv.mngr.tiles.getMainDirective();
        	}
            tile.mesh = new BABYLON.Mesh(tile.id, scene);

            //Apply vertexData to custom mesh
                
            var vertexData = vv.mngr.tiles.getMesh(tile, directive);
            if (!vertexData) { console.log ('error'); }
            else {
                vertexData.applyToMesh(tile.mesh);
                tile.mesh.material = tile.material;
            }                                           
        },        
                
        getTileOffset : function (level) {
//            var off = [0.01, 0.005, 0.005, 0.005, 0.003];
//            var off = [0.003, 0.003, 0.003, 0.003, 0.002, 0.002, 0.002];
//            var off = [0.001, 0.001, 0.001, 0.001, 0.002, 0.002, 0.002];
        	return 0;
            if (level < 0) return off[0];
            if (level > 6) return 0.001;
            return off[level];
        },
        
        registerTile : function (tile) {
            for (var i=vv.props.tiles.length-1; i>=0; i--) {
                var t = vv.props.tiles[i];
                if (t.id == tile.id) {
                    //console.log('tile found ' + tile.id);
                    return t;
                }
            }
            vv.props.tiles.push(tile);
            return tile;
        },	
        
        getMesh : function (tile, directive) {    
            var _mx = tile._mx;
            var _my = tile._my;
            
            var positions = [];
            var indices = [];
            
//            var division = 32;
            var division = 64;
            if (tile.level > 3) { division = Math.pow(2, tile.level + 2); }
            var step = Math.PI / division;
//            var _xw = division;
//            var _h = division;
//          var r = vv.props.r;
//            var r = 6.4 * vv.projection.planet.getCameraRadiusCoef(vv.utils.getCurrentLevel());
            var r = 6.4 * vv.mngr.camera.getCameraRadiusCoef(vv.utils.getCurrentLevel());
            var counter = 0;
            var uvs = [];
            
            var st = division / _my;
            
            var _psStart = tile.y * st;      // get start position
            var _psEnd = _psStart + st;     // get start position
            // may need adjustment?
            var _thStart = tile.x * st;
            
            for (var ps = _psStart; ps < _psStart + st ; ps++) {
                var degP = ps * step;
                var degP2 = (ps+1) * step;
                var y1 = r * Math.cos (degP);
                var y2 = r * Math.cos (degP2);
                var y3 = y2;
                var y4 = y1;
                var y5 = y2;
                var y6 = y1;

                var sdP = Math.sin(degP);
                var sdP2 = Math.sin(degP2);

                for (var th=_thStart; th < _thStart + st; th++) {
                    
                    var degT = th * step;
                    var degT2 = (th+1) * step;
                    if (!vv.props.isGeo) {
                    	degT = degT * 2;
                        degT2 = degT2 * 2;
                    }
                    var rcT = r * Math.cos(degT);
                    var rcT2 = r * Math.cos(degT2);
                    var rsT = r * Math.sin(degT);
                    var rsT2 = r * Math.sin(degT2);
                
                    var x1 = rcT * sdP;
                    var x2 = rcT * sdP2;
                    var x3 = rcT2 * sdP2;
                    var x4 = x1;
                    var x5 = x3;
                    var x6 = rcT2 * sdP;

                    var z1 = rsT * sdP;
                    var z2 = rsT * sdP2;
                    var z3 = rsT2 * sdP2;
                    var z4 = z1;
                    var z5 = z3;
                    var z6 = rsT2 * sdP;
                    
                    positions = positions.concat([x1, y1, z1, x2, y2, z2, x3, y3, z3, x4, y4, z4, x5, y5, z5, x6, y6, z6]);
                    for (var ind = 0; ind < 6; ind ++) {
                        indices.push(counter);
                        counter ++;
                    }
                    var u1 = (th  - _thStart) / (st * directive.factor) +  directive.off_x/directive.factor;
                    var u2 = (th - _thStart + 1) / (st * directive.factor) + directive.off_x/directive.factor;
                    if (th == _thStart + st - 1) {
                    	if ((window.devicePixelRatio * 10) % 10 != 0) {
//                    		u2 = u2 - 0.005;
                    		//u2 = u2 - 0.001;
                    	}
                    }

                    var _off = vv.mngr.tiles._offset;
                    var _offv = _off / 1.5;
//                    if (_offv < 0.001) { _offv = 0.001; }
                    
                    //if ((degP/Math.PI * 180) < 30 || (degP/Math.PI * 180) > 130) { _off = _off * 2.5;} 

                    
                    if (th == _thStart) { u1 += _off; }
                    if (th == _thStart + st - 1) { u2 -= _off; }             
                    var v1 = (1 - (ps - _psStart) / st ) / directive.factor + directive.off_y/directive.factor;
                    var v2 = (1 - (ps - _psStart +1 ) / st) / directive.factor + directive.off_y/directive.factor;
                    if (ps == _psStart) { v1 -= _offv; }
                    if (ps == _psStart + st - 1) { v2 += _offv; }
                    uvs = uvs.concat([ u1, v1, u1, v2, u2, v2 , u1, v1, u2, v2, u2, v1 ]);

                }
            }
            
            if (tile.y == _my) {} // return bottom part
            
            var normals = [];
            
            var vertexData = new BABYLON.VertexData();
            BABYLON.VertexData.ComputeNormals(positions, indices, normals);

            //Assign positions, indices and normals to vertexData
            vertexData.positions = positions;
            vertexData.indices = indices;
            vertexData.normals = normals;
            vertexData.uvs = uvs;
            vertexData.uvs2 = uvs;
            
            if (tile.x == 1 && tile.y == 3) {
//            	console.log(normals);
            }
            
            return vertexData;
            
        },
        
        correctTile : function (x, xmax, y, ymax) {
            var _x = x;
            var _y = y;
            if (x < 0 ) { _x = xmax + x; }
            else  if (x >= xmax) { _x = x - xmax;}
            if (y < 0 ) { 
                _y = (-1.0 * y) - 1;            }
            else  if (y >= ymax) { 
                _y = 2 * ymax - y - 1;
            }
            if (y < 0 || y >= ymax) {
                var _half = Math.floor(2*_x / xmax);
                var half = (_half == 0) ? 1 : 0;
                var off = _x % (xmax / 2);
                _x = (xmax / 2 * half) + off;
            }
            return [_x, _y];
        }, 
        
        getTileFromAB : function (level, alpha, beta) {
//            var obj = vv.mngr.tiles.validateXYRange(level, x, y);
            var t = vv.obj.Tile('void', 0, 0, level);
            if (t) {
                var x = Math.floor(vv.utils.camera2coord(alpha) * t._mx / 360.0);
                var y = Math.floor(vv.utils.camera2coord(beta) * t._my / 180.0);
                if (x < 0 ) { x = t._mx + x; }
                return [x % t._mx, y];
            } else {
                return [0,0];
            }
        },
        
        validateLevel : function (level) {
            if (level > vv.props.MAX_LEVEL || level < vv.props.MIN_LEVEL) return false;
            return true;
        },
        
        getTileId : function (baseId, x, y) {
        	if (x == -1 && y == -1) { return vv.mngr.tiles.getSphereTileId(baseId); }
            return "vvTile-" + baseId + '-' + x + '-' + y;
        },
        
        getSphereTileId : function (baseId) {
            return "vvTile-" + baseId + '-sphere';
        },
                
        checkPartial : function (tile) {
        	var counter = 0;
        	for (var i = 0; i<tile.parts.length; i++) {
        		if (tile.parts[i] === 1) { counter ++; }
        	}
        	if (counter == 256) {
        		tile.partial = false;
        	} 
        }
        
};
 }
 catch (e) { 
 // pass 
 }