try { 
this["vv"]["mngr"]["tween"] = // tween
{
	animations : [],
	animationCounter : 0,
	running : false,
	currentTask : null,
	timer : null,
	loadingImageCounter : 0,
	loadingImageTick : 7,
	loadingImageLimit : 100,
	
	init : function () {
		document.addEventListener(vv.events.VV_IMAGE_TWEEN, vv.mngr.tween.frame);
	},
	
	load : function (task) {
		if (! task.loaded ) {
			return;
		}
		var animation = new vv.obj.AnimationTask(vv.mngr.tween.animationCounter++, task.id, task.surface);
		vv.mngr.tween.animations.push(animation);
		vv.mngr.tween.start();		
	},
	
	getNextTask : function () {
		for (var i = vv.mngr.tween.animations.length -1; i >=0; i--) {
			var task = vv.mngr.tween.animations[0];	
			vv.mngr.tween.animations.splice(0,1);
			if (task.surface && task.surface.level == vv.utils.getCurrentLevel() || task.surface.level == 1) {
				var hidden = true;
				for (var j=0; j<task.surface.tiles.length; j++) {
		    		var tile = task.surface.tiles[j];
		    		if (tile.material && tile.alpha < 1.0) {			
		    			return task;
		    		}
				}
			}
		}		
		return null;
	},
	
	start : function () {
		if (vv.mngr.tween.running || vv.mngr.tween.animations.length == 0) { return; }
		vv.mngr.tween.currentTask = vv.mngr.tween.getNextTask();
		if (vv.mngr.tween.currentTask) {
			vv.mngr.tween.running = true;			
			vv.mngr.tween.startLoadImage();		
		}
	},
	
	stop : function () {
		clearInterval(vv.mngr.tween.timer);	
		vv.timer.loadingImage = false;
	},
	
	stopAndPlay : function () {
		if (vv.mngr.tween.currentTask) {
			vv.mngr.tween.currentTask.finished = true;
			vv.events.dispatchEvent(vv.events.VV_ANIMATION_END, vv.mngr.tween.currentTask);
			vv.mngr.tasks.remove(vv.mngr.tween.currentTask.id);
			vv.mngr.tween.currentTask = null;			
			//vv.mngr.tasks.dispatchNextTask();
		}		
		vv.mngr.tween.running = false;
		if (vv.mngr.tween.animations.length == 0) {
			vv.mngr.tween.stop();
		} else {
			vv.mngr.tween.start();			
		}
	},
	
	clearFrames : function () {
		if (!vv.mngr.tween.currentTask) {
			return;
		}
		for (var i=vv.mngr.tween.animations.length; i>=0; i--) {
			if (vv.mngr.tween.currentTask.id != vv.mngr.tween.animations[i].id) {
				vv.mngr.tween.animations.splice(i,1);				
			}
		}
	},
	
	frame : function () {
		if (!vv.mngr.tween.running) { return; }
		var task = vv.mngr.tween.currentTask;

    	var done = 0;
    	for (var i=0; i<task.surface.tiles.length; i++) {
    		var tile = task.surface.tiles[i];
    		if (tile.material && ! tile.partial) {
    			if (tile.alpha < 1.0) {
    				tile.alpha += 0.01;
        			if (tile.alpha > 1.0) { tile.alpha = 1.0; }
        			tile.material.alpha = tile.alpha;
    			}  else {
    				done++;
    			}		
    		} else if (tile.partial) {
    			done++;
    		}  			
    	}
    	if (done == task.surface.tiles.length) {
    		vv.mngr.tween.stopAndPlay();
    	}		
	},
	
	startLoadImage : function () {
		vv.mngr.tween.loadingImageCounter = 0;
		if (! vv.timer.loadingImage) {
			vv.timer.loadingImage = true;
			vv.mngr.tween.timer = setInterval( vv.mngr.tween.loadImageTick, vv.mngr.tween.loadingImageTick);			
		}
	},
		
	loadImageTick : function () {
		if (vv.timer.loadingImage) {
			vv.mngr.tween.loadingImageCounter ++;
			vv.events.vvImageTween();
		}
		if (vv.mngr.tween.loadingImageCounter >= vv.mngr.tween.loadingImageLimit ) {
			vv.mngr.tween.stopAndPlay();
		} 
	},
	
}

vv.mngr.tween.init();;
 }
 catch (e) { 
 // pass 
 }