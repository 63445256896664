try { 
this["vv"]["mngr"]["tasks"] = // tasks
{
	taskList : [],
	taskCounter : 0,
	
	TASK_CORE : 'core',
	TASK_LEVEL : 'level',
	TASK_MOVE : 'move',
	TASK_BASIC : 'basic',
	
	init : function () {
		document.addEventListener(vv.events.VV_LAYER_CHANGE, vv.mngr.tasks.clearTasks);
	},
	
	remove : function (id) {
		for (var i = 0; i<vv.mngr.tasks.taskList.length; i++) {
			if (vv.mngr.tasks.taskList[i].id == id) {
				vv.mngr.tasks.taskList.splice(i, 1);
			}
		}
		//vv.mngr.tasks.dispatchNextTask();
	},
	
	tilesLoaded : function (task) {
//		console.log(vv.mngr.tasks.taskList);
		task.loaded = true;
		// refresh tempTiles
		if (task.taskType == vv.mngr.tasks.TASK_CORE) {
			vv.mngr.surfaces.clearTempSurfaceTiles(task.surface);
			vv.mngr.surfaces.updateTempTiles(task.surface);
		} 
		vv.mngr.tween.load(task);			
		vv.mngr.tasks.dispatchNextTask();
	},
	
	clearTasks : function () {
		vv.mngr.tasks.taskList = [];
	},
	
	isDone : function () {
		if (vv.mngr.tasks.taskList.length == 0 || 
				(vv.mngr.tasks.taskList.length == 1 && vv.mngr.tasks.taskList[0].loaded)) {
			return true;
		} 
		return false;
	},
	
	dispatchNextTask : function () {
//		console.trace();
		vv.mngr.tasks.checkTiles();
		var id = -1;
		for (var i = 0; i<vv.mngr.tasks.taskList.length; i++) {
			var task = vv.mngr.tasks.taskList[i];
			if (task.running) {
				if (task.loaded) {
//					console.log("found running " + task.id);
					id = task.id;
				} else {
//					console.log('task ' + task.id + ' still running');
					return;
				}
			}
			if (! task.running) {
				task.running = true;
				if (id >=0 ) {
					vv.mngr.tasks.remove(id); 
				}
				vv.events.dispatchEvent(vv.events.VV_TILE_TASK_START, task);
				return;
			}
		}
	},
	
	checkTiles : function () {
		for (var i = vv.mngr.tasks.taskList.length-1; i>=0; i--) {
			var task = vv.mngr.tasks.taskList[i];
			var ready = true;
			for (var j=0; j<task.surface.tiles.length; j++) {
	    		var tile = task.surface.tiles[j];
	    		if (tile.partial || !tile.ready()) {
	    			ready = false;
	    			break;
	    		}
			}
			if (ready) {
				vv.mngr.tasks.taskList.splice(i, 1);				
			}
		}
	},
	
	makeTiles : function (s, taskType, cx, cy) {
		if (taskType == vv.mngr.tasks.TASK_CORE) {
			for (var i = vv.mngr.tasks.taskList.length-1; i>=0; i--) {
				var task = vv.mngr.tasks.taskList[i];
				if (task.taskType != vv.mngr.tasks.TASK_CORE && (task.running && ! task.finished)) {
					vv.mngr.tasks.taskList.splice(i, 1);						
				}
			}
//			vv.mngr.tasks.clearTasks();
		}
		if (taskType == vv.mngr.tasks.TASK_LEVEL) {
			for (var i = vv.mngr.tasks.taskList.length-1; i>=0; i--) {
				var task = vv.mngr.tasks.taskList[i];
				if (task.taskType != vv.mngr.tasks.TASK_CORE && (task.running && ! task.finished)) {
//				if (task.taskType == vv.mngr.tasks.TASK_MOVE ) {
//					if (! task.running || ( task.running && task.finished)) {
						vv.mngr.tasks.taskList.splice(i, 1);	
//					}
				}
			}
		}
		if (taskType == vv.mngr.tasks.TASK_MOVE) {
			for (var i = vv.mngr.tasks.taskList.length-1; i>=0; i--) {
				task = vv.mngr.tasks.taskList[i];
				if ((task.taskType == vv.mngr.tasks.TASK_MOVE || task.taskType == vv.mngr.tasks.TASK_BASIC) && ( task.running && task.finished)) {
//				if (task.taskType == vv.mngr.tasks.TASK_MOVE) {
//					if (! task.running || ( task.running && task.finished)) {
						vv.mngr.tasks.taskList.splice(i, 1);
//					}
				}
			}			
		}
		var task = new vv.obj.Task(vv.mngr.tasks.taskCounter++, s, taskType);
		task.centerX = cx;
		task.centerY = cy;
		vv.mngr.tasks.taskList.push(task);
		vv.mngr.tasks.dispatchNextTask();
		console.log(vv.mngr.tasks.taskList);
	},
}

vv.mngr.tasks.init();
;
 }
 catch (e) { 
 // pass 
 }