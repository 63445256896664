try { 
this["vv"]["entity"]["Planet"] = // Planet
function (id) {
	this.id = id;
    this.scene = null;
    this.position = null;
    this.emptyWorld = true;
    this.multiverse = {};
    this.uniqueMapCounter = -1;		// updated when layer is changed
    this.relativeMapCounter = 0;	// updated each time map is moved
    this.active = true;
	
	vv.components.add(new vv.obj.Component({
		id: this.id,
		type: 'planet',
		instance: this
	}) );
	
	this.init = function () {
		document.addEventListener(vv.events.VV_ANIMATION_END, this.animationEnd);
		document.addEventListener(vv.events.VV_LAYER_CHANGE, this.layerChange);
	}
	
	this.animationEnd = function (evt) {
		var task = evt.detail;
		var earth = vv.components.getById('Earth').instance;
		if (earth.emptyWorld) {
			earth.emptyWorld = false;
			vv.components.removeComponentById('Logo');
		}
		if (task.surface.type == 'core' && task.surface.uniqueMap != this.uniqueMapCounter) {
			vv.mngr.surfaces.clean(earth);
		}
		if (task.surface.level == 0) {
			task.surface.sphereTextureLoaded = true;
			vv.mngr.surfaces.hideLevel1(earth);
		}
		if (task.surface.level <= 1) {
/*			task.surface.radius = 1.0;
			var sid = task.surface.getMainSphereId();
			var id = vv.mngr.tiles.getTileId(sid, 0, 0); 
			var tile = task.surface.getTileById(id);
			vv.utils.removeMeshById(tile.id);
    		tile.mesh = BABYLON.MeshBuilder.CreateSphere(tile.id, { diameter: vv.props.r*2 * task.surface.radius }, task.surface.scene);
    		vv.mngr.tiles.mainTile = tile.mesh;
    		tile.mesh.material = tile.material;*/  
    		//    		vv.mngr.tiles.mainTile = tile.mesh;

/*			var sid = task.surface.getMainSphereId();
			var id = vv.mngr.tiles.getTileId(sid, 0, 0); 
			var t = task.surface.getTileById(id);
			vv.mngr.tiles.setTexture(task, t, t.checksum);*/
//			vv.mngr.tiles.updateTile(task.surface, t);
		}
		if (task.taskType != vv.mngr.tasks.TASK_CORE) {
			vv.mngr.surfaces.clearTempTiles(task.surface);
		}
//		console.log(vv.mngr.tasks.taskList);
		console.log(earth.multiverse);
		console.log('Total meshes: ' + earth.scene.meshes.length + ', materials: ' + earth.scene.materials.length + ', textures: ' + earth.scene.textures.length);
	}
	
	this.layerChange = function (evt) {
		var earth = vv.components.getById('Earth').instance;
		earth.bigBang();
	}
	
	this.create = function (scene) {
		this.scene = scene;
        vv.mngr.entities.create(this);
		this.bigBang();
    }
	
	this.bigBang = function () {
		
		this.uniqueMapCounter++;
		//vv.mngr.tween.clearFrames(); -- needs fixing
		vv.mngr.tasks.clearTasks();
		this.setMultiverse(1);
		this.setMultiverse(vv.utils.getCurrentLevel());
	}
	
	this.update = function (type) {
		//var currentId = this.getMVId();
		for (var id in this.multiverse) {
			//if (id != currentId) { continue; }
			var level = vv.utils.getCurrentLevel();
	    	if (type == 'level') {
	    		for (var i=1; i<this.multiverse[id].length; i++) {
	    			if (this.multiverse[id][i] && this.multiverse[id][i]["main"]) {
	    				if (this.multiverse[id][i]["temp"]) {
	    					this.multiverse[id][i]["temp"].update(type);
	    				}
	    				this.multiverse[id][i]["main"].update(type);
	    			}
	    			if (i == 1 && this.multiverse[id][0]) {
	    				this.multiverse[id][0]["main"].update(type);
	    			} 
	    		}
	    		if (! this.multiverse[id][level]) {
	    			this.setMultiverse(level);	    				
	    		}
	    	} else if (type == 'location') {
	    		if (this.multiverse[id]) {
	    			if (!this.multiverse[id][level]) {
						console.log('no surface to do location. ' + level ) ;
						return;	    				
	    			}
    				if (this.multiverse[id][level]["temp"]) {
    					this.multiverse[id][level]["temp"].update(type);
    				}
					this.multiverse[id][level]["main"].update(type);

	    		}
	    	}
		}
	}
	
	this.setMultiverse = function (level) {
		var id = this.getMVId();
		if (! this.multiverse[id]) {
			this.multiverse[id] = new Array(20);
		}
		if (! this.multiverse[id][level]) {
			this.multiverse[id][level] = {};
		}
		var type = (level == 1) ? 'core' : 'plane';
		if (level > 2 && ! this.multiverse[id][level]["temp"]) {
			this.multiverse[id][level]["temp"] = new vv.obj.Surface(this.uniqueMapCounter, type, level, this.multiverse[id]);
			this.multiverse[id][level]["temp"].displayType = "temp";
			this.multiverse[id][level]["temp"].create(this.scene);				
		}
		if (!vv.props.isGeo) {
			if (! this.multiverse[id][level]["conversion"]) {
				this.multiverse[id][level]["conversion"] = vv.conversion.setEmptyBorderTiles(level);
			}
		}
		if (! this.multiverse[id][level]["main"]) {
			this.multiverse[id][level]["main"] = new vv.obj.Surface(this.uniqueMapCounter, type, level, this.multiverse[id]);
			this.multiverse[id][level]["main"].create(this.scene);				
		}
		console.log(this.multiverse[id]);
	}
	
	this.getMVId = function () {
		return 'surf-' + this.uniqueMapCounter;
	}
	
	this.getEmptyId = function () {
		return this.id +  '-empty';
	}
	
	this.init();

	this.rotate = function () {
    	var level = vv.utils.getCurrentLevel();
//    	this.position();
    	for (var id in this.multiverse) {
    		if (this.multiverse[id][level] && this.multiverse[id][level]["main"]) {
    			var s = this.multiverse[id][level]["main"];
            	var sid = s.getMainSphereId();
            	var stid = vv.mngr.tiles.getSphereTileId(sid);
            	var tile = s.getTileById(stid);
            	if (tile && tile.mesh) {
//            		tile.mesh.rotate(BABYLON.Axis.Y, -(vv.mngr.rotation.earthRotation), BABYLON.Space.LOCAL);

            		
            		//            		tile.mesh.rotation.y = - vv.mngr.rotation.currentRotation;
//                	tile.mesh.rotate(new BABYLON.Vector3(0, 1, 0), -(vv.mngr.rotation.earthRotation), BABYLON.Space.LOCAL); 
//                	tile.mesh.position = vv.mngr.rotation.earthPosition;
//                	console.log('update to ', id, level);
//            		console.log(tile.mesh.rotation);
            	}
    		}
    	}
	}
}
;
 }
 catch (e) { 
 // pass 
 }