try { 
this["vv"]["obj"]["Component"] = function (obj) {
	this.id = "";
	this.type = "";
	this.instance = null;
	this.mouseWheel = null;
	
	for (var prop in obj) {
		this[prop] = obj[prop];
	}
};
 }
 catch (e) { 
 // pass 
 }