try { 
this["vv"]["entity"]["Moon"] = // Moon 
function (id) {
	this.scene = null;
	this.currentLevel = -1;
	this.id = id;
    this.MIN_LEVEL = 0;
    this.MAX_LEVEL = 1;
    this.light = null;
    this.sphere = null;
    this.position = new BABYLON.Vector3(0, 0, 0);
    
	vv.components.add(new vv.obj.Component({
		id: this.id,
		type: 'moon',
		instance: this
	}) );
	
	this.create = function (scene) {
		this.scene = scene;
        this.update();
    }
	
	this.update = function (type) {
		if (this.currentLevel == vv.utils.getCurrentLevel()) {
			return;
		}
        this.currentLevel = vv.utils.getCurrentLevel();
		vv.utils.removeMeshById(this.id);
		if (this.light) { this.light.setEnabled(false);}
		if (this.currentLevel >= this.MIN_LEVEL && this.currentLevel <= this.MAX_LEVEL) {
			this.createMoon();			
		}
	}
		
	this.createMoon = function () {
        var mat = this.scene.getMaterialByID(this.id);
        if (! mat) {
            mat = new BABYLON.StandardMaterial(this.id, this.scene);
            mat.diffuseTexture = new BABYLON.Texture("/images/moon.jpg", this.scene);
            mat.backFaceCulling = false;
            mat.alpha = 1.0;
        }
        if (!this.light) {
            this.light = new BABYLON.SpotLight("spotLight", new BABYLON.Vector3(0, 0, vv.props.r * 10), new BABYLON.Vector3(0, 0, -1), Math.PI / 2, 10, this.scene);
        	this.light.diffuse = new BABYLON.Color3(1, 1, 1);
        	this.light.specular = new BABYLON.Color3(1, 1, 1);
        	this.light.intensity = 0.08;        	
//        	this.light.intensity = 0.38;        	
        } else {
        	this.light.setEnabled(true);	
        }
        
//        var sphereM = BABYLON.MeshBuilder.CreateSphere(this.id, { diameter: vv.props.r*0.27*2 }, this.scene);
//        var sphereM = BABYLON.MeshBuilder.CreateSphere(this.id, { diameter: vv.props.r*0.27 }, this.scene);
        var sphereM = BABYLON.MeshBuilder.CreateSphere(this.id, { diameter: vv.props.r*0.27 }, this.scene);
        sphereM.material = mat;
        sphereM.position.z = vv.props.r * 8;
        sphereM.rotate(new BABYLON.Vector3(0.5, -0.75, 0.5), 90, BABYLON.Space.WORLD);
        this.sphere = sphereM;
//        this.light = new BABYLON.PointLight("pointLight", new BABYLON.Vector3(0, 0, vv.props.r * 5), this.scene);
//        this.light.intensity = 0.07;
	}
	
	this.rotate = function () {
		var step = vv.mngr.rotation.earthDay + vv.mngr.rotation.currentRotation / (2 * Math.PI); 
		var origin = BABYLON.Vector3.Zero();
		
//    	var radius = 100*(1-0.017*0.017)/(1+0.017* Math.cos( step / 365.25*360));
    	var radius = vv.props.r * 10;
    	var diff = (step - Math.floor(step / 28.0) * 28) / 28.0;
//    	console.log(diff);
    	var angle = -diff * 2 * Math.PI;
		var v = new BABYLON.Vector3(radius, angle, 0);
		this.position = vv.utils.spher2Cart(v).add( origin );
//		console.log(this.position);
		this.sphere.position = this.position;
	}
};
 }
 catch (e) { 
 // pass 
 }