try { 
this["vv"]["obj"]["Tile"] = function (sId, x, y, level) {

    this.x = x;
    this.y = y;
    this.level = level;
    
    // id is based on: surface, unique map counter, level, x and y
    this.id = vv.mngr.tiles.getTileId(sId, this.x, this.y);    
    this.relevance = 0.0;
    this.active = false;
    this.mesh = null;
    this.loaded = false;
    this.texture = null;
    this.material = null;
    this.outer = false;
    this.mapCounter = -1;
    this.linkedId = null;		// used by temp tile to remember which tile is used for texture
    this.partial = true;
    this.mapIndex = -1;
    this.parts = new Array(256);
    this.alpha = 0.0;
    this._mx = null;
    this._my = null;    
    this.rawData = null;
    this.checksum = 0;
    this.composite = false;
    
    this.validateXYRange = function () {
        if (this.level > vv.props.MAX_LEVEL || this.level < vv.props.MIN_LEVEL) return null;
        this._mx = this.getMaxX();
        this._my = this.getMaxY();
        
        if (this.y >= this._my) return null;
        if (this.x < 0 ) { this.x = this.getMaxX() + this.x; }
        if (this.x >= this._mx ) { 
            var _max = this.getMaxX();
            var val = this.x % _max;
            this.x = val;
        }
        return vv.mngr.tiles.registerTile(this);
    }
        
    this.getMaxX = function () {
        return Math.pow(2, this.level + 2);
//        return Math.pow(2, this.level+1);
    }
    
    this.getMaxY = function () {
        var level = (vv.props.isGeo) ? this.level + 1 : this.level+2;
        return Math.pow(2, level);
    }
    
    this.addToRelevance = function (rel) {
        if (this.relevance + rel <= 1.0) { this.relevance += rel;}
    }

    this.isValid = function () {
        if (this.mesh && this.texture && this.material) return true;
        return false;
    }
    
    this.isTextureReady = function () {
        if (! this.texture) return false;
        return this.texture.loaded;
    }
    
    this.clearMesh = function () {
        vv.utils.removeMeshById(this.id);
        this.mesh = null;    	
    }
    
    this.clearMaterial = function () {
    	vv.utils.removeMaterialById(this.id);
        this.material = null;
    }
        
    this.clearMeshAndMaterial = function () {
    	this.clearMesh();
        this.clearMaterial();
    }
    
    this.ready = function () {
    	if (this.material && this.texture && this.texture.data) { return true; }
    	return false;
    }
        
    this.clearTexture = function () {
    	vv.utils.removeTextureById(this.id);
        this.texture = null;
    } 
    
    
    this.clearAll = function () {
		this.linkedId = null;
        this.clearMeshAndMaterial();
        this.clearTexture();
        this.rawData = null;
        this.mapIndex = -1;
    }

    // check for valid tile range and whether the tile already exists. Otherwise return reference to that tile
    return this.validateXYRange();
}
;
 }
 catch (e) { 
 // pass 
 }