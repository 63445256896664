try { 
this["vv"]["mngr"]["loader"] = // mngr.loader
// loader
{ 
    start : function () {
        
        if (vv.utils.getIEVersion() > 0) { 
            vv.props.isIE = true;
            Math.log2 = Math.log2 || function(x){return Math.log(x)*Math.LOG2E;};
            Math.log10 = Math.log10 || function(x){return Math.log(x)*Math.LOG10E;};
        }
        
        document.getElementById(vv.props.map.getTarget()).style.display="none";

        
        vv.mngr.loader.createDivs(vv.props.wrapper);
        
        vv.props.canvas = document.getElementById("vvCanvas");
        
        if (vv.props.mapType == 'ol') {
            vv.plugins.ol.main.init('vv-support');
        }
        
        vv.utils.getDPI();
    },
    
    createDivs : function (parentDiv) {
    
        var maps = document.createElement("div");
        maps.setAttribute("id", "vv-support");
        parentDiv.appendChild(maps);
        
        var div = document.createElement("div");
        div.setAttribute("id", "vvCanvasWrapper");
        var canvas = document.createElement("canvas");
        canvas.setAttribute("id", "vvCanvas");
        canvas.setAttribute("class", "vv-canvas");
        canvas.setAttribute("touch-action", "none");
        div.appendChild(canvas);
        parentDiv.appendChild(div);
    }

};
 }
 catch (e) { 
 // pass 
 }