try { 
this["vv"]["entity"]["Stars"] = // Stars 
function (id) {
	this.scene = null;
	this.currentLevel = -1;
	this.id = id;
    this.MIN_LEVEL = 0;
    this.MAX_LEVEL = 5;
    
	vv.components.add(new vv.obj.Component({
		id: this.id,
		type: 'stars',
		instance: this
	}) );
	
	this.create = function (scene) {
		this.scene = scene;
        this.update();
    }
	
	this.update = function (type) {
		if (this.currentLevel == vv.utils.getCurrentLevel()) {
			return;
		}
//		console.log('updating milky way to ' + this.currentLevel + ' r:' + (vv.props.r*30));
        this.currentLevel = vv.utils.getCurrentLevel();
		vv.utils.removeMeshById(this.id);
		if (this.currentLevel >= this.MIN_LEVEL && this.currentLevel <= this.MAX_LEVEL) {
			this.createMilkyWay();			
		}
	}
	
	this.createMilkyWay = function () {
        var mat = this.scene.getMaterialByID(this.id);
        if (! mat) {
            mat = new BABYLON.StandardMaterial(this.id, this.scene);
            mat.diffuseTexture = new BABYLON.Texture("/images/milkyway2.jpg", this.scene);
            mat.backFaceCulling = false;
            mat.alpha = 0.5;
        }
        var sphereM = BABYLON.MeshBuilder.CreateSphere(this.id, { diameter: vv.props.r*300 }, this.scene);
        sphereM.material = mat;
//        sphereM.rotate(new BABYLON.Vector3(0.5, -0.75, 0.5), 90, BABYLON.Space.WORLD);
        sphereM.rotation.x = 0.5;
        sphereM.rotation.y = 0.7;
        sphereM.rotation.z = 0.5;
	}
	
	this.rotate = function () {
		
	}
};
 }
 catch (e) { 
 // pass 
 }