try { 
this["vv"]["conversion"] = // conversion
{
    originShift : 2 * Math.PI * 6378137 / 2.0,
    tileShift : 2 * Math.PI * 6378137 / 256,
    maxMercLat : 0,

	init : function () {
		vv.conversion.maxMercLat = vv.conversion.metersYToLat(vv.conversion.originShift);
	},

    
    metersYToLat : function (my) {
        var lat = (my / vv.conversion.originShift) * 180.0

        lat = 180 / Math.PI * (2 * Math.atan( Math.exp( lat * Math.PI / 180.0)) - Math.PI / 2.0)
        return lat;
    },
    
    pixelsToMetersY : function (py, levelX) {
        return (py * levelX - vv.conversion.originShift);
	},
	
	LatToMeters : function (lat) {
		var my = Math.log( Math.tan((90 + lat) * Math.PI / 360.0 )) / (Math.PI / 180.0);
		return my * vv.conversion.originShift / 180.0;
	},
		
	resolution : function (level) {        
        return vv.conversion.tileShift / Math.pow(2, level);
	},

	setEmptyBorderTiles : function (level) {
		var obj = new Object();
		var ct = vv.obj.Tile('void', 0, 0, level);
		if (level < 4) {
			obj.minTile = -1;
			obj.maxTile = ct._my;			
		} else {
            var size = 180 / ct._my;

			for (var i=0; i < ct._my / 4; i++) {
	            var y = 90 - (i+1) * size;	// get bottom coordinate    
	            if (y < vv.conversion.maxMercLat) {
	            	obj.minTile = i-1;
	            	obj.maxTile = ct._my - i;
	            	break;
	            }
			}
		}
		return obj;
	},	
	
}
vv.conversion.init();;
 }
 catch (e) { 
 // pass 
 }