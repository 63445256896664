try { 
this["vv"] = this["vv"] || {};
this["vv"]["components"] = // components
{
	getById : function (id) {
		for (var i=0; i<vv.props.components.length; i++) {
	    	if (vv.props.components[i].id == id) { return vv.props.components[i]; }
	    }
	    return null;
	},
	
	removeComponentById : function (id) {
		for (var i=0; i<vv.props.components.length; i++) {
	    	if (vv.props.components[i].id == id) {
	    		vv.props.components[i].instance.destroy();
	    		vv.props.components.splice(i,1);
	    		return;
	    	}
	    }
	},
	
	add : function (component) {
    	vv.props.components.push(component);
	},
	
	getActivePlanet : function (id) {
		for (var i=0; i<vv.props.components.length; i++) {
	    	if (vv.props.components[i].type == 'planet' && vv.props.components[i].active) { return vv.props.components[i]; }
	    }
		return null;
	}
};
 }
 catch (e) { 
 // pass 
 }