try { 
this["vv"]["mngr"]["textures"] = // mngr.textures
// textures
{     
    getTextureById : function (id) {
        for (var i=vv.props.textures.length-1; i>=0; i--) {
            if (vv.props.textures[i].id == id) {
                return vv.props.textures[i];
            }
        }
        return null;
    },
   
    checkForDuplicates : function () {
    	var arr = {};
    	for (var i=0; i<vv.props.scene.textures.length; i++ ) {
    		var id = vv.props.scene.textures[i].name;
    		if (!arr[id]) { arr[id] = 1;}
    		else { console.log('duplicate id = '+ id);}
    	}
    }
};
 }
 catch (e) { 
 // pass 
 }