try { 
this["vv"]["plugins"]["ol"]["obj"] = this["vv"]["plugins"]["ol"]["obj"] || {};
this["vv"]["plugins"]["ol"]["obj"]["Map"] = function (id, level, index) {
    this.id = id;
    this.index = index;
    this.minZoom = 5;
    this.maxZoom = 20;
    this.level = level;
    this.currentSize = 0;
    this.previousSize = 0;
    this.loading = false;
    this.ready = false;
    this._resized = false;
    this.active = false;
    this.cx = 0;            // which tile is the center (top left corner)
    this.cy = 0;
    this.ct = vv.obj.Tile('void', 0, 0, this.level);
    this.width = 1792;
    this.height = 1792;
    this.pcFunc = null;
    this._scaled = false;
    this._ratio = 1;
    this.task = null;
    this.extent = null;
    this.zoom = 0;


    if (this.level < 0 ) { this.level = 0;}
    if (vv.props.isGeo) {
        this.minZoom = this.level + 2;
        this.maxZoom = this.level + 2;
        if (this.level >= 2) { this.maxZoom = vv.props.MAX_LEVEL + 2;}
        if (this.level == 0) { this.width = 1024; this.height = 512;}
        if (this.level == 1) { this.width = 2048; this.height = 1024;}
        this.extent = [-180, -90, 180, 90];   
        this.zoom = this.level + 2;
    } else {
        this.minZoom = this.level + 2;
        this.maxZoom = this.level + 2;
        if (this.level >= 2) { this.maxZoom = vv.props.MAX_LEVEL + 2;}
        if (this.level == 0) { this.width = 1024; this.height = 1024;}
        if (this.level == 1) { this.width = 2048; this.height = 2048;}
        this.extent = [-20037508.342789244, -20037508.342789244, 20037508.342789244, 20037508.342789244];   
        this.zoom = this.level + 2;
    }
    
    this.map = new ol.Map({
        target: this.id,
        interaction: null,
        control: null,
        view: new ol.View({
            projection: ol.proj.get(vv.props.projection),
            extent: this.extent,
            center: [0, 0],
            zoom: this.zoom,
            minZoom: this.minZoom,
            maxZoom: this.maxZoom,
        })
    });
    
    this.setLevel = function (level) {
    	this.level = level;
    	this.ct = vv.obj.Tile('void', 0, 0, this.level);
    }
    
    this.setTileInfo = function (level, cx, cy) {
        this.startLoading();
//        if (this.level > 1 || (vv.props.isGeo && this.level > 1)) {
        if (this.level > 1) {
            this.cx = cx;
            this.cy = cy;
            this.level = level;
            var size = 360 / this.ct._mx;
            var arr = this.getMapCenter(cx, cy);
            var x = arr[0] * size - 180 + size / 2;
            var y = 90 - arr[1] * size - size / 2;
            if (!vv.props.isGeo) {
            	size = 180 / this.ct._my;
            	y = 90 - arr[1] * size - size / 2;
            	var startTileY = (y > 0) ? y - size / 2 : y + size / 2;

            	if (Math.abs(startTileY) > vv.conversion.maxMercLat) {
            		console.log('invalid location');
            	} else {
            		var my = vv.conversion.LatToMeters(y);
            		var ty = (this.ct._my / 2) - Math.floor( my / (2 * vv.conversion.originShift / this.ct._my));
            	}
            	
            	this.cy = ty;
                var half = Math.PI * 6378137;	// equivalent to 90 deg; 180=90 for longitude
                var sizeM = 2 * half / this.ct._mx;
                arr = this.getMapCenter(cx, ty);
                x = arr[0] * sizeM - half + sizeM / 2;
                y = half - arr[1] * sizeM - sizeM / 2;
            }
        	this.map.getView().setZoom(level+2);
            this.map.getView().setCenter([x,y]);            
        }

    }
    
    this.getMapCenter = function (cx, cy) {
        var x = cx;
        if (cx - 3 < 0) { x = 3; }
        else if (cx + 3 >= this.ct._mx) { x = this.ct._mx - 4; }
        var y = cy;
        if (cy - 3 < 0) { y = 3; }
        else if (cy + 3 >= this.ct._my) { y = this.ct._my - 4; }
//        console.log('getMapCenter ' , x, y);
        return [x, y];
    }
    
    this.getTileRange = function () {
        var range = {};
        if (vv.props.isGeo) {
	        if (this.level < 2) {
	            range["maxx"] = Math.pow(2, this.level+2);
	            range["maxy"] = Math.pow(2, this.level+1);
	            range["startx"] = 0;
	            range["starty"] = 0;
	            range["offsetx"] = 0;
	            range["offsety"] = 0;
	        } else {
	            range["startx"] = 0;
	            range["maxx"] = 7;
	            range["offsetx"] = this.cx - 3;
	            if (this.cx - 3 < 0) { 
	                range["offsetx"] = 0;
	                range["maxx"] = this.cx + 4;
	            } else if (this.cx +3 >= this.ct._mx) {                
	                range["startx"] = 4 - (this.ct._mx - this.cx);
	                range["offsetx"] = range["offsetx"] - range["startx"];
	                range["maxx"] = 7;
	            }
	            range["starty"] = 0;
	            range["maxy"] = 7;
	            range["offsety"] = this.cy - 3;
	            if (this.cy - 3 < 0) { 
	                range["offsety"] = 0;
	                range["maxy"] = this.cy + 4;
	            } else if (this.cy +3 >= this.ct._my) {                
	                range["starty"] = 4 - (this.ct._my - this.cy);
	                range["offsety"] = range["offsety"] - range["starty"];
	                range["maxy"] = 7;
	            }
	                        
	        }
        } else {
	        if (this.level < 2) {
	            range["maxx"] = Math.pow(2, this.level+2);
	            range["maxy"] = Math.pow(2, this.level+2);
	            range["startx"] = 0;
	            range["starty"] = 0;
	            range["offsetx"] = 0;
	            range["offsety"] = 0;
	        } else {
	            range["startx"] = 0;
	            range["maxx"] = 7;
	            range["offsetx"] = this.cx - 3;
	            if (this.cx - 3 < 0) { 
	                range["offsetx"] = 0;
	                range["maxx"] = this.cx + 4;
	            } else if (this.cx +3 >= this.ct._mx) {                
	                range["startx"] = 4 - (this.ct._mx - this.cx);
	                range["offsetx"] = range["offsetx"] - range["startx"];
	                range["maxx"] = 7;
	            }
	            range["starty"] = 0;
	            range["maxy"] = 7;
	            range["offsety"] = this.cy - 3;
	            if (this.cy - 3 < 0) { 
	                range["offsety"] = 0;
	                range["maxy"] = this.cy + 4;
	            } else if (this.cy +3 >= this.ct._my) {                
	                range["starty"] = 4 - (this.ct._my - this.cy);
	                range["offsety"] = range["offsety"] - range["starty"];
	                range["maxy"] = 7;
	            }
	                        
	        }
        }
        return range;
    }
              
    this.postCompose = function () {
        
        var ctx = this.getCtx();
        var img = ctx.getImageData(0, 0, this.width, this.height);
        var sum = 0;
        for (var i=0; i<img.data.length; i++) {
            sum += img.data[i];
        }
        this.currentSize = sum;
        this.loading = true;
    }
    
    this.setPostComposeHandler = function () {
        if (! this.pcFunc) {
            // try to load the function from vv.plugins.ol.main and save into the object
            this.pcFunc = this.map.on('postcompose', vv.plugins.ol.main.postComposeHandler);       
            window.dispatchEvent(new Event('resize'));
        }
    }
    
    this.unsetPostComposeHandler = function () {
        if (this.pcFunc) {
            this.map.un('postcompose', vv.plugins.ol.main.postComposeHandler);
            this.pcFunc = null;            
        }
    }
    
    this.startLoading = function () {
        //console.log('starting loading ' + this.id);
        this.currentSize = 0;
//        this.loading = true;
        this.loading = false;
        this.active = false;
        this.previousSize = 0;
    }
    
    this.stopLoading = function () {
        //console.log('stop loading ' + this.id + ', ' + this.level );
        this.loading = false;
        this.active = true;
        //vv.plugins.ol.main.postCompose(null);
    }
    
    this.checkStatus = function () {
        if (! this.loading) { return; }
        if (this.currentSize != this.previousSize) {
            this.previousSize = this.currentSize;
            return;
        }
        if (this.currentSize != 0) {
            this.stopLoading();
            if (this.index == vv.plugins.ol.main.activeMap) {
                vv.plugins.ol.utils.loadTexture(this);                
            } 
        }
    }
    
    this.getCtx = function () {
        return document.getElementById(this.id).childNodes[0].childNodes[0].getContext('2d');    	
    }
    
    this.scale = function () {
    	if (this._scaled) { return; }
    	this._scaled = true;
        this.ratio = window.devicePixelRatio;
        var ctx = this.getCtx();
        ctx.scale(1/this.ratio, 1/this.ratio);
    }
    
    this.unscale = function () {
    	if (!this._scaled) { return;}
    	this._scaled = false;
        var ctx = this.getCtx();
        ctx.scale(this.ratio, this.ratio);
    }

    
};
 }
 catch (e) { 
 // pass 
 }