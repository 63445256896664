try { 
this["vv"]["entity"]["Sun"] = // Sun 
function (id) {
	this.scene = null;
    this.currentLevel = -1;
    this.id = id;
    this.emitter = null;
    this.MIN_LEVEL = 0;
    this.MAX_LEVEL = 100;
    this.MAX_REALISM_LEVEL = 1;
    this.showSun = false;
    this.lights = [];
    this.timer = null;
    this.ticker = 0;
    this.currentLights = "";
    this.lightSpeedTick = 7;

    vv.components.add(new vv.obj.Component({
        id: this.id,
        type: 'sun',
        instance: this
    }) );

    
	this.create = function (scene) {
		this.scene = scene;
		this.lights.push (vv.utils.setDirectionalLight("Direct1", this.scene,  vv.mngr.rotation.centerPosition, new BABYLON.Color3(1,1,1) ));
		this.lights.push (vv.utils.setPointLight("Point1", this.scene, vv.mngr.rotation.centerPosition, new BABYLON.Color3(1,1,1), new BABYLON.Color3(1,0.97,0.64)));
		this.lights.push (vv.utils.setHemiLight("Hemi1", this.scene, new BABYLON.Vector3(0,0, 1), new BABYLON.Color3(1, 1, 1)));
		this.lights.push (vv.utils.setHemiLight("Hemi2", this.scene, new BABYLON.Vector3(0,0,-1), new BABYLON.Color3(1, 1, 1)));
		var dir1 = vv.props.scene.getLightByID("Direct1");
		dir1.intensity = 0.5;
		var point1 = vv.props.scene.getLightByID("Point1");
		point1.intensity = 0.1;
		
        this.update();
        
    }
	
	this.update = function (type) {
		if (this.currentLevel == vv.utils.getCurrentLevel()) {
			return;
		}
        this.currentLevel = vv.utils.getCurrentLevel();
        var light = vv.props.scene.getLightByID("Direct1");
        var light2 = vv.props.scene.getLightByID("Point1");
        var hemi1 = vv.props.scene.getLightByID("Hemi1");
        var hemi2 = vv.props.scene.getLightByID("Hemi2");
        if (vv.props.realism && this.currentLevel <= this.MAX_REALISM_LEVEL) {
            if (! this.emitter) {
            	this.emitter = new BABYLON.VolumetricLightScatteringPostProcess('godrays', { passRatio: 0.9, postProcessRatio: 2.5 }, vv.mngr.camera.activeCamera, null, 100, BABYLON.Texture.BILINEAR_SAMPLINGMODE, vv.props.engine, false);
            	this.emitter.mesh.material.diffuseTexture = new BABYLON.Texture("/images/sun2.png", this.scene, true, false, BABYLON.Texture.BILINEAR_SAMPLINGMODE);
            	this.emitter.mesh.material.diffuseColor = new BABYLON.Color3(1,0.97,0.64);
            	this.emitter.mesh.material.diffuseTexture.hasAlpha = true;               

            }
			var scale = vv.props.r * 4;
			this.emitter.mesh.scaling = new BABYLON.Vector3(scale, scale, scale);
			this.emitter.mesh.position = vv.mngr.rotation.centerPosition;
			if (this.currentLights != "direct") {
				this.currentLights = "direct";
				this.switchLights();				
			}
        } else {        	
        	if (this.emitter && this.emitter.mesh) {
            	this.emitter.mesh.position = new BABYLON.Vector3(0, 0, 0);        		
        	}
			if (this.currentLights != "hemi") {
				this.currentLights = "hemi";
				this.switchLights();								
			}

        }		
	}   
	
	this.switchLights = function () {
        var light = vv.props.scene.getLightByID("Direct1");
        var light2 = vv.props.scene.getLightByID("Point1");
        var hemi1 = vv.props.scene.getLightByID("Hemi1");
        var hemi2 = vv.props.scene.getLightByID("Hemi2");

    	light.setEnabled(true);
    	light2.setEnabled(true);
    	hemi1.setEnabled(true);
    	hemi2.setEnabled(true);

		if (this.ticker > 0) {
			clearInterval(this.timer);
		}
		this.timer = setInterval(this.tick, this.lightSpeedTick);	
	}
	
	this.tick = function () {
		var sun = vv.components.getById('Sun').instance;
		sun.ticker ++;
        var light = vv.props.scene.getLightByID("Direct1");
        var light2 = vv.props.scene.getLightByID("Point1");
        var hemi1 = vv.props.scene.getLightByID("Hemi1");
        var hemi2 = vv.props.scene.getLightByID("Hemi2");

		if (sun.ticker >= 100) {
			sun.ticker = 0;
			clearInterval(sun.timer);
			if (sun.currentLights == "direct") {
	        	light.setEnabled(true);
	        	light2.setEnabled(true);
	        	hemi1.setEnabled(false);
	        	hemi2.setEnabled(false);				
			} else {
	        	light.setEnabled(false);
	        	light2.setEnabled(false);
	        	hemi1.setEnabled(true);
	        	hemi2.setEnabled(true);
			}
		} else {
	        if (sun.currentLights == "direct") {
	        	light.intensity = sun.ticker * 0.03;
	        	light2.intensity = sun.ticker * 0.001;
//	        	light2.intensity = 0;
	        	hemi1.intensity = (100 - sun.ticker) * 0.01;
	        	hemi2.intensity = (100 - sun.ticker) * 0.01;
			} else {
				light.intensity = (100 - sun.ticker) * 0.03;
				light2.intensity = (100 - sun.ticker) * 0.03 / 20.0;
				hemi1.intensity = sun.ticker * 0.01;
				hemi2.intensity = sun.ticker * 0.01;
			}

		}		
	}
	
	this.rotate = function () {
        var light = vv.props.scene.getLightByID("Direct1");
        var light2 = vv.props.scene.getLightByID("Point1");
        light.direction = vv.mngr.rotation.centerPosition.negate();
        this.emitter.mesh.position = vv.mngr.rotation.centerPosition;
//        console.log(vv.mngr.camera.activeCamera.beta / Math.PI * 180, (Math.cos(vv.mngr.camera.activeCamera.beta) * 100));
        light2.position = new BABYLON.Vector3(vv.mngr.rotation.centerPosition.x, Math.cos(vv.mngr.camera.activeCamera.beta / 2.0 + Math.PI/4.0) * 100, vv.mngr.rotation.centerPosition.z);
        //-35.5, .. 0 .. -94.5
        // -158  ... -161/ -180 - sun / omni
        // camera is 50
	}
};
 }
 catch (e) { 
 // pass 
 }