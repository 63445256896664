try { 
this["vv"]["obj"]["Surface"] = // Surface
function (uniqueMap, type, level, parent) {
    
	this.uniqueMap = uniqueMap;
	this.level = level;
	this.currentLevel = -1;
    this.id = vv.mngr.surfaces.getSurfaceId(this.uniqueMap, this.level);
    this.type = type;  // [core, plane]
	this.centerX = -1;
	this.centerY = -1;
    this.alpha = 1.0;
//    this.radius = 1.005;
    this.radius = 1;
    this.visible = false;
    this.scene = null;
    this.displayType = 'main';		// [main, temp]
    this.tiles = [];
    this.animated = false;	// if used as part of an animation
    this.parent = parent;
    this.mainSphere = null;
    this.backSphere = null;
    this.sphereTextureLoaded = false;
        
    this.create = function (scene) {
    	this.scene = scene;
    	this.currentLevel = vv.utils.getCurrentLevel(); 
    	var taskType = (this.level == 1) ? vv.mngr.tasks.TASK_CORE : vv.mngr.tasks.TASK_LEVEL;
//    	vv.mngr.surfaces.createTiles(this, taskType);
    	this.show(taskType);
    }
    
    this.setSurface = function (cx, cy) {
    	this.centerX = cx;
    	this.centerY = cy;
    }
    
    this.getBaseId = function () {
    	var temp = (this.displayType == 'temp') ? 'temp_' : '';
    	return temp + this.id;
    }
    
    this.getMainSphereId = function () {
    	return 'mainSphere-'+ this.id;
    }
    
    this.clear = function () {
    	for (var i=0; i<this.tiles.length; i++) {
    		this.tiles[i].clearAll();    		
    	}
    	this.tiles = [];
    }
    
    this.update = function (type) {
//    	console.log('update ' + this.id + ', ' + type + ', '+ this.currentLevel + ', ' + vv.utils.getCurrentLevel() + ' visible ' + this.visible);
    	if (this.displayType == 'temp' && vv.utils.getCurrentLevel() != this.level) {
    		this.clear();
    	}
    	if (type == 'level') {
    		var taskType = (this.level == 1) ? vv.mngr.tasks.TASK_CORE : vv.mngr.tasks.TASK_LEVEL;
    		if (this.level == 1 && vv.utils.getCurrentLevel() == 0 && 
    				this.parent[0] && this.parent[0]["main"] && this.parent[0]["main"].sphereTextureLoaded) {
    			
    			taskType = vv.mngr.tasks.TASK_LEVEL;
    			this.type = "plane";
    		} else if (this.level == 1) {
    			this.type = "core";
    		}
    		this.updateVisibility(taskType);
    	} else {
    		if (vv.utils.getCurrentLevel() != this.level && ! this.visible ) { return; }
    		if (this.level <=1 && vv.utils.getCurrentLevel() == this.currentLevel) { return; }
    		if (this.level > 3) { vv.mngr.surfaces.clearLevelTiles(this);}
    		if (! this.visible) { this.updateVisibility(vv.mngr.tasks.TASK_MOVE); }
    		else { 
    			vv.mngr.surfaces.createTiles(this, vv.mngr.tasks.TASK_MOVE, 0, 0);
    			if (this.displayType == "main") {
//            		vv.mngr.surfaces.createTiles(this, vv.mngr.tasks.TASK_BASIC, -7, 0);
//                	vv.mngr.surfaces.createTiles(this, vv.mngr.tasks.TASK_BASIC, 7, 0);        		
            	}
    		}
    	}    	
    }
    
    this.updateVisibility = function (taskType) {
    	if (this.currentLevel != vv.utils.getCurrentLevel()) {    			
			this.currentLevel = vv.utils.getCurrentLevel();
			this.hide();
			if (this.level != vv.utils.getCurrentLevel() && this.level !=1) {
				for (var i=0; i<this.tiles.length; i++) {
					this.tiles[i].clearMaterial();
				}
				
			}
		}    		
		this.show(taskType);
    }
    
    this.show = function (taskType) {
    	if (this.visible) { return; }
    	if (this.type == 'core' || this.currentLevel == this.level) {
//    		console.log('creating task', taskType, this.level);
        	this.visible = true;
        	vv.mngr.surfaces.createTiles(this, taskType, 0, 0);
        	if (this.displayType == "main") {
//        		vv.mngr.surfaces.createTiles(this, vv.mngr.tasks.TASK_BASIC, -7, 0);
//            	vv.mngr.surfaces.createTiles(this, vv.mngr.tasks.TASK_BASIC, 7, 0);        		
        	}
//        	vv.mngr.surfaces.createTiles(this, taskType, 0, 0);
//        	console.log('showing ' + this.id);
		}
    }
    
    this.hide = function () {
    	if (! this.visible) { return; }
		this.visible = false;
    	for (var i=0; i<this.tiles.length; i++) {
			this.tiles[i].clearMesh();
		}
    	//this.clearMainSphere();
    }
    
    this.getTileById = function (id) {
    	for (var i=0; i<this.tiles.length; i++) {
    		if (this.tiles[i].id == id) {
    			return this.tiles[i];
    		}
    	}
    	return null;
    }
    
    this.removeTileById = function (id) {
    	for (var i=0; i<this.tiles.length; i++) {
    		if (this.tiles[i].id == id) {
    			this.tiles[i].clearAll();
    			this.tiles.splice(i,1);
    		}
    	}    	
    }
    
    return this;
};
 }
 catch (e) { 
 // pass 
 }