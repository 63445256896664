try { 
this["vv"]["props"] = // props
{
		r : 64,
		isIE : false,
		components : [],
		maxRadius : 0,
		inchKm : 39370,
		wheelPrecision : 100,
		wrapper : null,
		position : null,
        canvas : null,
        engine : null,
        scene : null,
        dpi : 90,
        timeout : 2000,
        map : null,
        surfaces : {},
        MIN_LEVEL: 0,
        MAX_LEVEL: 12,
        tiles : [],
        mapType : "ol",
        projection : null,
        isGeo : false,
        realism : true,
        
        setMap : function (map) {
        	vv.props.map = map;
        	vv.props.projection = map.getView().getProjection().getCode();
            if (!(vv.props.projection == 'EPSG:4326' || vv.props.projection == 'EPSG:3857' )) {
            	return false;
            }
            if (vv.props.projection == 'EPSG:4326') { vv.props.isGeo = true; }
            return true;
        }
};
 }
 catch (e) { 
 // pass 
 }