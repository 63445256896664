try { 
this["vv"]["utils"] = // utils 
{
	_tan : 0.383864035,
//		_tan : 0.363970234,
	
	getDPI : function () {
		var dpi = document.createElement("div");
		dpi.setAttribute("id", "vvDpi"); 
		dpi.setAttribute("style", "height:1in;left:-100%;position:absolute;top:-100%;width:1in;");
		vv.props.wrapper.appendChild(dpi);
		var devicePixelRatio = window.devicePixelRatio || 1;
		vv.props.dpi = Math.round(dpi.offsetHeight * devicePixelRatio);
	},
	
	updateDependencies : function () {
//        var x = vv.utils.camera2coord(vv.mngr.camera.activeCamera.alpha) - 180;
//        var y = 90 - vv.utils.camera2coord(vv.mngr.camera.activeCamera.beta);
//            vv.props.map.getView().setZoom(vv.props.position.level);
//            vv.props.map.getView().setCenter([x,y]);

	},
	
	setOLMapView : function () {
/*			var rad = vv.projection.planet.camera.radius;
			//console.log(rad);
			var height = vv.props.canvas.height / 2;
			//var scale = Math.round((rad * vv.utils._tan) * _coef * 39370 * vv.props.dpi / height);
			var r = vv.props.r;
			var R = vv.props.maxRadius;
			var _level = Math.log2((R- r) / (rad-r));
			//var level = _level - 1; 			// account for map layer starting at 1
			if (level < 0) { level = 0;}
//			_level = (vv.utils._getMaxHeight() / (rad * vv.utils._tan));
			var level = _level;
			
			var x = vv.utils.camera2coord(vv.projection.planet.camera.alpha) - 180;
			var y = 90 - vv.utils.camera2coord(vv.projection.planet.camera.beta);
//			console.log(level + ' : ' + x + " , " + y + ', ' + vv.projection.planet.camera.radius);
			console.log(level + ', ' + vv.projection.planet.camera.radius);
//			var y = 90 - vv.entity.Earth.camera.beta / Math.PI * 180;
			vv.props.map.getView().setZoom(_level+1);
			vv.props.map.getView().setCenter([x,y]);
			vv.props.position.level = level;
			vv.props.position.alpha = vv.projection.planet.camera.alpha;
			vv.props.position.beta = vv.projection.planet.camera.beta;
			vv.props.position.radius = vv.projection.planet.camera.radius;
			vv.engine.setScene();

			//console.log(level + ', ' + _level);
//			console.log(vv.props.dpi * vv.props.inchKm * width / 1000000);
*/
	},
	
	getCurrentLevel : function () {
		var l =  Math.round(vv.props.position.level - 0.5);
//            var l =  Math.round(vv.props.position.level - 1.0);
		if (l <= 0) l = 0.0;
		return l;
		
	},
			
	setVVMapView : function () {
//			var p = vv.props.map.getView().getCenter();
	    var p = [0, 0];
		var alpha = (p[0] + 180) * Math.PI / 180;
		var beta = (90 - p[1]) * Math.PI / 180;
		if (alpha < 0.0) { alpha = 0.0;}
		if (alpha > 2*Math.PI) {alpha = 2*Math.PI;}
		if (beta < 0.0) { beta = 0.0;}
		if (beta > Math.PI) { beta = Math.PI;}
		vv.mngr.camera.activeCamera.alpha = alpha;
		vv.mngr.camera.activeCamera.beta = beta;
		//var level = vv.props.map.getView().getZoom();
		var level = 3;
// 			console.log('2d: ' + level);
		if (level < 0) { level = 0;}
		if (level > 9.1) { level = 9.1;}
		var r = vv.props.r;
		var R = vv.props.maxRadius;
		var X = level * Math.log10(2);
		var Y = Math.pow(10, X);
		var res = (R-r) / Y + r;
		
		vv.mngr.camera.activeCamera.radius = res;
		vv.props.position.level = level;
		vv.props.position.alpha = alpha;
		vv.props.position.beta = beta;
		//var _level = Math.log((R- r) / (rad-r)) / Math.log(2);
		vv.mngr.camera.activeCamera.radius = vv.utils._getMaxHeight() / ((level + 1)* vv.utils._tan);
// 			console.log('radius : ' + vv.projection.planet.camera.radius);
		vv.engine.setScene();
	},
	
	camera2coord : function (angle) {
		return angle / Math.PI * 180;	// returns value from 0 .. 360
	},
	
	_getMaxHeight : function () {
		return vv.mngr.camera.activeCamera.upperRadiusLimit * vv.utils._tan;
	},
	
	getIEVersion: function() {
        var sAgent = window.navigator.userAgent;
        var Idx = sAgent.indexOf("MSIE");
        // If IE, return version number.
        if (Idx > 0) {
            return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf(".", Idx)));
        }
        // If IE 11 then look for Updated user agent string.
        else if (navigator.userAgent.match(/Trident\/7\./)) {
            return 11;
        } else { //It is not IE
            return 0;
        }
    },
    	    
    tile2long : function (x,z) {
        return (x/Math.pow(2,z+1)*360-180);
    },
    
    tile2lat : function (y,z) {
        return ((Math.pow(2,z) - y)/Math.pow(2,z)*180-90);
    },
    padNum : function (num, size) {
        var s = "000000000" + num;
        return s.substr(s.length-size);
    },
    	    
    getLevelFromRadius : function (rad) {
        var r = vv.props.r;
        var R = vv.props.maxRadius;
        var level = Math.log2((R - r) / (rad-r)) - 1.0;
        //console.log(rad + ', ' +r + ', ' + R + ', ' + level);
        if (level < 0) { level = 0;}
        return level;
    },
    
    getRadiusFromLevel : function (level) {
        var r = vv.props.r;
        var R = vv.props.maxRadius;
        return (r + (R-r) / Math.pow(2, level));
    },
    
    setHemiLight : function (id, scene, vector, color) {
        var light = new BABYLON.HemisphericLight(id, vector, scene);
        light.diffuse = color;
        light.specular = new BABYLON.Color3(0, 0, 0);
        light.groundColor = new BABYLON.Color3(0, 0, 0);
        light.intensity = 0;
        return light;
    },
    
    setDirectionalLight : function (id, scene, vector, color) {
    	var light = new BABYLON.DirectionalLight(id, vector, scene);
    	light.diffuse = color;
    	light.specular = new BABYLON.Color3(0,0,0);
    	light.intensity = 0;
    	return light;
    },

    setPointLight : function (id, scene, vector, color, specular) {
    	var light = new BABYLON.PointLight(id, vector, scene);
    	light.diffuse = color;
    	light.specular = specular;
    	light.intensity = 0;
    	return light;    	
    },
    
    removeLightById : function (id) {
    	var light = vv.props.scene.getLightsByID(id);
    	if (light) { light.dispose(); }
    },
    
    removeMeshById : function(id) {
        var meshes = vv.props.scene.getMeshesByID(id);
        if (meshes.length > 0) {
        	meshes[0].dispose();            	
        }
    },
    
    removeMaterialById : function (id) {
        var mat = vv.props.scene.getMaterialByID(id);
        if (mat) {  mat.dispose(); }
    },
    
    removeTextureById : function (id) {
        for (var i=0; i<vv.props.scene.textures.length; i++ ) {
            if (vv.props.scene.textures[i].name == id) {
                vv.props.scene.textures.splice(i, 1);
                return;
            }
        }
    },
    
    // convert spherical to cartesian
	spher2Cart : function (v) {			
		return new BABYLON.Vector3(	v.x * Math.sin(v.y) * Math.cos(v.z),
									v.x * Math.sin(v.y) * Math.sin(v.z),
									v.x * Math.cos(v.y));
	},

};
 }
 catch (e) { 
 // pass 
 }